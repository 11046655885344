import PageWrapper from "@/components/PageWrapper";
import { auth } from "@/config/firebase";
import { ROUTES } from "@/constants/routes";
import { useSession } from "@/providers/session";
import { useUserContext } from "@/providers/user";
import { CircularProgress } from "@mui/material";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import ActiveSession from "./ActiveSession";
import FirstTurnVote from "./FirstTurnVote";
import JoinSession from "./JoinSession";
import WaitingOnPartner from "./WaitingOnPartner";

function ActiveSessionPage() {
  const { activeSession, setActiveSessionId } = useSession();
  const { user, isAuthenticated } = useUserContext();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { ownerUser: owner, acceptedBy = [] } = activeSession || {};

  const isSessionOwner =
    isAuthenticated && owner && user?.id === activeSession?.owner;

  // redirect if not authenticated
  onAuthStateChanged(auth, async (authedUser) => {
    if (!authedUser) {
      navigate(ROUTES.LOGIN, {
        state: { from: location },
      });
    }
  });

  // set activeSessionId on mount
  useEffect(() => {
    if (id) {
      setActiveSessionId(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper className="px-0">
      <div className="w-full flex flex-1 flex-col justify-center items-center gap-4">
        {!activeSession && (
          <div className="flex flex-1 items-center justify-center">
            <CircularProgress />
          </div>
        )}

        {acceptedBy.length === 0 && (
          <div className="px-4 flex flex-col flex-1 items-center justify-center">
            {isSessionOwner ? <WaitingOnPartner /> : <JoinSession />}
          </div>
        )}

        <FirstTurnVote />

        <ActiveSession />
      </div>
    </PageWrapper>
  );
}

export default ActiveSessionPage;
