import { useUserContext } from "@/providers/user";
import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Portal,
} from "@mui/material";

function LoadingUserDialog({ onClose }: { onClose: () => void }) {
  const { loggingOut, logout } = useUserContext();

  const handleLogoutButtonClick = async () => {
    await logout();
    onClose();
  };

  return (
    <Portal>
      <Dialog open={true} fullWidth maxWidth="md">
        <DialogTitle>Loading User</DialogTitle>
        <DialogContent dividers>
          <div className="flex flex-1 items-center justify-center">
            <CircularProgress />
          </div>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loggingOut}
            disabled={loggingOut}
            onClick={handleLogoutButtonClick}
            color="primary"
          >
            Logout
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Portal>
  );
}

export default LoadingUserDialog;
