import { classNames } from "@/utils/styles";
import React from "react";
import Header from "../Header";

function PageWrapper({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <>
      <Header className="fixed left-0 top-0 right-0" />
      <main className="h-[calc(100dvh-4rem)] pt-14">
        <div
          className={classNames(
            "h-full box-border p-4 flex flex-col items-center justify-between gap-4",
            className || ""
          )}
        >
          {children && children}
        </div>
      </main>
    </>
  );
}

export default PageWrapper;
