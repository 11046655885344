import { FirebaseError } from "firebase/app";
import {
  GoogleAuthProvider,
  User,
  getAuth,
  onAuthStateChanged,
  reload,
  signInWithPopup,
} from "firebase/auth";
import {
  Timestamp,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { db } from "../config/firebase";
import { ROUTES } from "../constants/routes";
import { IUser } from "../types";

const authedRoutes = [
  ROUTES.CREATE_NAME,
  ROUTES.ROBOT_NAME,
  ROUTES.CREATE_SESSION,
  ROUTES.SESSION,
  ROUTES.DASHBOARD,
];

const provider = new GoogleAuthProvider();
const auth = getAuth();

interface UserContextValue {
  isAuthenticated: boolean;
  user: IUser | null;
  loginWithGoogle: () => Promise<void>;
  logout: () => Promise<void>;
  loggingOut: boolean;
  updateUser: (user: IUser) => void;
  updateName: (name: string) => void;
}

const UserContext = createContext<UserContextValue | undefined>(undefined);

export const useUserContext = (): UserContextValue => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useLocation();

  const [firebaseUser, setFirebaseUser] = useState<User | null>(null);

  const [user, setUser] = useState<IUser | null>(null);
  const [loggingOut, setLoggingOut] = useState(false);

  const isAuthenticated = user !== null;

  const loginWithGoogle = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
      }
    }
  };

  const updateUser = async (user: IUser) => {
    setUser(user);

    // update the user in the database
    try {
      const docRef = doc(db, "users", user.id);
      await setDoc(docRef, user);
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
      }
    }
  };

  const updateName = async (name: string) => {
    if (user) {
      const updatedUser = { ...user, name };
      updateUser(updatedUser);
    }
  };

  useEffect(() => {
    // handle authentication state changes
    onAuthStateChanged(auth, async (authedUser) => {
      setFirebaseUser(authedUser);

      // set the user to null if not authenticated
      if (!authedUser) {
        // clear data
        setUser(null);
        setFirebaseUser(null);

        // redirect to login page if on an authed route
        if (authedRoutes.includes(pathname)) {
          window.location.href = ROUTES.LOGIN;
        }

        setUser(null);

        return;
      }

      // check if user exists in the database
      const getExistingUser = async (uid: string): Promise<IUser | null> => {
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          return docSnap.data() as IUser;
        } else {
          return null;
        }
      };

      const storedUser = await getExistingUser(authedUser.uid);

      if (storedUser) {
        // User exists in the database
        setUser(storedUser);
      } else {
        // User does not exist in the database
        const newUser: IUser = {
          id: authedUser.uid,
          name: authedUser.displayName || "",
          email: authedUser.email || "",
          avatar: authedUser.photoURL || "",
          createdAt: serverTimestamp() as Timestamp,
          updatedAt: serverTimestamp() as Timestamp,
        };

        // Add the user to the database
        await setDoc(doc(db, "users", authedUser.uid), newUser);

        setUser(newUser);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = async () => {
    try {
      setLoggingOut(true);
      await auth.signOut();
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
      }
    } finally {
      setLoggingOut(false);
    }
  };

  // refresh the user's session on reload
  useEffect(() => {
    if (isAuthenticated && firebaseUser) {
      reload(firebaseUser);
    }
  }, [isAuthenticated, firebaseUser]);

  const value: UserContextValue = {
    isAuthenticated,
    user,
    loginWithGoogle,
    updateUser,
    updateName,
    logout,
    loggingOut,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
