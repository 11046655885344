import { db } from "@/config/firebase";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Portal,
  TextareaAutosize,
} from "@mui/material";
import {
  Timestamp,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { MdSend } from "react-icons/md";
import colors from "tailwindcss/colors";

interface IFeedbackDTO {
  content: string;
  createdAt: Timestamp;
}

interface FeedbackDialogProps {
  onClose: () => void;
}

function FeedbackDialog({ onClose }: FeedbackDialogProps) {
  const [feedbackContent, setFeedbackContent] = useState("");
  const [sendingFeedback, setSendingFeedback] = useState(false);

  const handleSendFeedback = async () => {
    if (!feedbackContent) return;

    setSendingFeedback(true);

    const feedback: IFeedbackDTO = {
      content: feedbackContent,
      createdAt: serverTimestamp() as Timestamp,
    };

    try {
      // post to firebase
      const feedbackRef = collection(db, "landingFeedback");
      await addDoc(feedbackRef, feedback);

      setFeedbackContent("");
      onClose();
      enqueueSnackbar("Feedback sent", {
        variant: "success",
        autoHideDuration: 1500,
        style: { backgroundColor: colors.purple[800], color: "white" },
      });
    } catch (error) {
      enqueueSnackbar("Failed to send feedback", {
        variant: "error",
      });
    } finally {
      setSendingFeedback(false);
    }
  };

  return (
    <Portal>
      <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>How can we improve?</DialogTitle>
        <DialogContent dividers>
          <div>
            <TextareaAutosize
              minRows={4}
              className="w-full rounded-md border-purple-800 text-lg p-2"
              onChange={(e) => setFeedbackContent(e.target.value)}
              value={feedbackContent}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <LoadingButton
            loading={sendingFeedback}
            disabled={!feedbackContent || sendingFeedback}
            onClick={handleSendFeedback}
            variant="outlined"
            endIcon={<MdSend />}
          >
            Send
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Portal>
  );
}

export default FeedbackDialog;
