import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Portal,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FaRobot } from "react-icons/fa";
import { useSession } from "../../providers/session";
import { useUserContext } from "../../providers/user";
import Conversation from "./Conversation";
import MyTurn from "./MyTurn";

function ActiveSession() {
  const {
    activeSession,
    requireUserMediation,
    removeUserMediation,
    addUserAcknowledgedMediation,
  } = useSession();
  const { user } = useUserContext();

  const [partnerMenuAnchorEl, setPartnerMenuAnchorEl] =
    useState<HTMLElement | null>(null);
  const showPartnerMenu = Boolean(partnerMenuAnchorEl);

  const [userAcknowledgedMediation, setUserAcknowledgedMediation] =
    useState<boolean>(false);

  const {
    isTurnBased,
    participantUsers: sessionUsers = [],
    usersRequiringMediation = [],
    id: activeSessionId,
    usersAcknowledgedMediation = [],
  } = activeSession || {};

  const partner = sessionUsers.find(
    (participant) => participant.id !== user?.id
  );

  // const firstTurnVote = useMemo(() => {
  //   return activeSession?.firstTurnVote || {};
  // }, [activeSession?.firstTurnVote]);

  // const firstTurnUserId = useMemo(() => {
  //   if (!user || !partner) return null;

  //   if (Object.keys(firstTurnVote).length < sessionUsers.length) {
  //     return null;
  //   }

  //   // determine who goes first based on the votes
  //   const votes = Object.values(firstTurnVote);

  //   // return the participant with the most votes
  //   const participantVotesDict = votes.reduce(
  //     (acc, curr) => {
  //       acc[curr] = (acc[curr] || 0) + 1;
  //       return acc;
  //     },
  //     {} as Record<IUserID, number>
  //   );

  //   const participantIds = Object.keys(participantVotesDict);
  //   const participantVotes = Object.values(participantVotesDict);

  //   const maxVotes = Math.max(...participantVotes);
  //   const maxVotesIndex = participantVotes.indexOf(maxVotes);

  //   // if it's a draw, choose randomly
  //   if (participantVotes.filter((v) => v === maxVotes).length > 1) {
  //     return participantIds[Math.floor(Math.random() * participantIds.length)];
  //   }

  //   return participantIds[maxVotesIndex];
  // }, [firstTurnVote, partner, sessionUsers.length, user]);

  // const currentTurnUserId = useMemo(() => {
  //   // check for a conversation
  //   const messages = activeSession?.messages || [];
  //   // if there are no messages, it's the first turn

  //   if (messages.length === 0) {
  //     return firstTurnUserId;
  //   }

  //   // if the conversation is not empty, check the last message
  //   const lastMessage = messages[messages.length - 1];
  //   const lastMessageUserId = lastMessage.userId;

  //   // return the userId that does not match the last message
  //   return lastMessageUserId === user?.id ? partner?.id : user?.id;
  // }, [activeSession?.messages, firstTurnUserId, partner?.id, user?.id]);

  const isMyTurn = true;
  // const isMyTurn = useMemo(() => {
  //   if (isTurnBased) {
  //     return user?.id === currentTurnUserId;
  //   }

  //   return true;
  // }, [currentTurnUserId, isTurnBased, user?.id]);

  const userAvatarSize = 75;
  // const partnerAvatarSize = useMemo(() => {
  //   if (isTurnBased && !isMyTurn) {
  //     return 100;
  //   }

  //   return 75;
  // }, [isMyTurn, isTurnBased]);

  const partnerAvatarSize = 75;
  // const userAvatarSize = useMemo(() => {
  //   if (isTurnBased && isMyTurn) {
  //     return 100;
  //   }

  //   return 75;
  // }, [isMyTurn, isTurnBased]);

  // check usersAcknowledgedMediation
  useEffect(() => {
    if (!user) return;

    if (
      usersAcknowledgedMediation.includes(user.id) &&
      !userAcknowledgedMediation
    ) {
      setUserAcknowledgedMediation(true);
    }
  }, [user, userAcknowledgedMediation, usersAcknowledgedMediation]);

  if (!partner || !user || sessionUsers.length < 2 || !activeSessionId) {
    return null;
  }

  // if (
  //   isTurnBased &&
  //   Object.keys(firstTurnVote).length !== sessionUsers.length
  // ) {
  //   return null;
  // }

  return (
    <>
      <div className="flex flex-1 flex-col w-full">
        <div className="py-2">
          <Typography
            align="center"
            color="GrayText"
            className="text-lg"
            sx={{ mb: 1 }}
          >
            Session with {partner.name}
          </Typography>

          <div className="flex flex-row items-center justify-center gap-2">
            <div className="relative">
              <Avatar
                src={user?.avatar}
                alt={user?.name}
                sx={{
                  width: userAvatarSize,
                  height: userAvatarSize,
                }}
                imgProps={{
                  referrerPolicy: "no-referrer",
                }}
              />

              {usersRequiringMediation.includes(user.id) && (
                <>
                  <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-secondary-600 rounded-full opacity-50" />

                  <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                    <FaRobot size={50} color="white" />
                  </div>
                </>
              )}
            </div>

            <div
              className="relative"
              onClick={(e) => setPartnerMenuAnchorEl(e.currentTarget)}
            >
              <Avatar
                src={partner.avatar}
                alt={partner.name}
                sx={{
                  width: partnerAvatarSize,
                  height: partnerAvatarSize,
                }}
                imgProps={{
                  referrerPolicy: "no-referrer",
                }}
              />

              {usersRequiringMediation.includes(partner.id) && (
                <>
                  <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-secondary-600 rounded-full opacity-50" />

                  <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                    <FaRobot size={50} color="white" />
                  </div>
                </>
              )}
            </div>

            <Menu
              open={showPartnerMenu}
              onClose={() => setPartnerMenuAnchorEl(null)}
              anchorEl={partnerMenuAnchorEl}
            >
              {usersRequiringMediation.includes(partner.id) ? (
                <MenuItem
                  onClick={() => {
                    removeUserMediation(activeSessionId, partner.id);
                    setPartnerMenuAnchorEl(null);
                  }}
                >
                  Turn off required mediation
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => {
                    requireUserMediation(activeSessionId, partner.id);
                    setPartnerMenuAnchorEl(null);
                  }}
                >
                  Make mediation required
                </MenuItem>
              )}
            </Menu>
          </div>
        </div>

        <div className="w-full flex flex-1 flex-col">
          <Conversation />

          {!isMyTurn && (
            <div className="flex flex-1 flex-col items-center justify-center">
              <Typography
                variant="h6"
                align="center"
                color="GrayText"
                className="text-lg mb-2"
              >
                {partner.name}'s turn
              </Typography>
            </div>
          )}

          {isMyTurn && <MyTurn />}
        </div>

        {/* used for spacing in flex */}
        <div />
      </div>

      <Portal>
        <Dialog
          open={
            usersRequiringMediation.includes(user.id) &&
            !userAcknowledgedMediation
          }
        >
          <DialogTitle>Mediation required</DialogTitle>
          <DialogContent dividers>
            <Typography sx={{ mb: 1 }}>
              {partner.name} has requested mediated dialog from you.
            </Typography>
            <Typography>
              Please acknowledge that you understand this requirement.
            </Typography>
          </DialogContent>
          <DialogActions>
            <MenuItem
              onClick={() => {
                addUserAcknowledgedMediation(activeSessionId, user.id);
              }}
            >
              I understand
            </MenuItem>
          </DialogActions>
        </Dialog>
      </Portal>
    </>
  );
}

export default ActiveSession;
