import { createTheme } from "@mui/material";
import colors from "tailwindcss/colors";

const theme = createTheme({
  typography: {
    fontFamily: '"IBM Plex Sans", sans-serif',
    h1: {
      fontFamily: '"IBM Plex Serif", serif',
    },
    h2: {
      fontFamily: '"IBM Plex Serif", serif',
    },
    h3: {
      fontFamily: '"IBM Plex Serif", serif',
    },
    h4: {
      fontFamily: '"IBM Plex Serif", serif',
    },
    h5: {
      fontFamily: '"IBM Plex Serif", serif',
    },
    h6: {
      fontFamily: '"IBM Plex Serif", serif',
    },
    body1: {
      fontFamily: '"IBM Plex Sans", sans-serif',
    },
    body2: {
      fontFamily: '"IBM Plex Sans", sans-serif',
    },
    button: {
      fontFamily: '"IBM Plex Sans", sans-serif',
    },
  },
  palette: {
    primary: colors.purple,
    secondary: {
      main: colors.white,
      contrastText: colors.purple[800],
    },
  },
});

export default theme;
