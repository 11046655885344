import { Avatar, Button, Typography } from "@mui/material";
import { FirebaseError } from "firebase/app";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useMemo, useState } from "react";
import { db } from "../../config/firebase";
import { useSession } from "../../providers/session";
import { useUserContext } from "../../providers/user";
import { ISession, IUserID } from "../../types";

function FirstTurnVote() {
  const { activeSession, activeSessionId } = useSession();
  const { user } = useUserContext();

  const { isTurnBased, participantUsers: sessionUsers = [] } =
    activeSession || {};

  const partner = sessionUsers.find(
    (participant) => participant.id !== user?.id
  );

  const [firstTurnVoteUserId, setFirstTurnVoteUserId] =
    useState<IUserID | null>(null);

  const firstTurnVoteId = useMemo(() => {
    if (!activeSession || !user) return null;

    return (
      firstTurnVoteUserId || activeSession.firstTurnVote?.[user.id] || null
    );
  }, [activeSession, firstTurnVoteUserId, user]);

  const handleGoFirstVote = async (participantId: IUserID) => {
    if (!activeSessionId || !user) return;

    setFirstTurnVoteUserId(participantId);

    try {
      const sessionDoc = await getDoc(doc(db, "sessions", activeSessionId));
      const session = sessionDoc.data() as ISession | undefined;

      if (!session) {
        console.error("No matching session.");
        return;
      }

      // update the session with the vote
      const votes = session.firstTurnVote || {};
      const updatedVotes = {
        ...votes,
        [user.id]: participantId,
      };

      await setDoc(doc(db, "sessions", activeSessionId), {
        ...session,
        firstTurnVote: updatedVotes,
      });
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
      }
    }
  };

  if (
    !partner ||
    !user ||
    !activeSession ||
    !isTurnBased ||
    sessionUsers.length < 2 ||
    Object.keys(activeSession.firstTurnVote).length >= sessionUsers.length
  ) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col flex-1">
        <Typography
          variant="h6"
          align="center"
          color="GrayText"
          className="text-lg !mb-4"
        >
          Session with {partner.name}
        </Typography>

        <div className="flex flex-row items-center justify-center">
          <Avatar
            src={user?.avatar}
            alt={user?.name}
            sx={{
              width: 100,
              height: 100,
              mr: 4,
            }}
            imgProps={{
              referrerPolicy: "no-referrer",
            }}
          />

          <Avatar
            src={partner.avatar}
            alt={partner.name}
            sx={{
              width: 75,
              height: 75,
            }}
            imgProps={{
              referrerPolicy: "no-referrer",
            }}
          />
        </div>
      </div>

      <div className="px-2 w-full">
        <Typography
          variant="h6"
          align="center"
          color="GrayText"
          className="text-lg !mb-4"
        >
          Who should go first?
        </Typography>

        <div className="flex flex-row gap-2">
          {sessionUsers.map((participant) => (
            <Button
              key={participant.id}
              variant={
                firstTurnVoteId === participant.id ? "contained" : "outlined"
              }
              fullWidth
              size="large"
              onClick={() => handleGoFirstVote(participant.id)}
            >
              {participant.id === user.id ? "Me" : participant.name}
            </Button>
          ))}
        </div>
      </div>

      <div />
    </>
  );
}

export default FirstTurnVote;
