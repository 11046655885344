import { Button, Typography } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { JoinActiveSessionDialog } from "../../components";
import { ROUTES } from "../../constants/routes";
import { useOnboarding } from "../../providers/onboarding";
import { useUserContext } from "../../providers/user";
import { OnboardingStep } from "../../types/onboarding";

function RobotName() {
  const { updateOnboarding } = useOnboarding();
  const { user } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [showJoiningActiveSessionDialog, setShowJoiningActiveSessionDialog] =
    useState(false);

  const isJoiningActiveSession = !!location?.state?.from;

  let userName = "";
  if (user) {
    userName = user.name;
  }

  const handleNext = async () => {
    // update onboarding
    await updateOnboarding(OnboardingStep.RobotIntro);

    if (isJoiningActiveSession) {
      setShowJoiningActiveSessionDialog(true);
      return;
    }

    // navigate to dashboard
    navigate(ROUTES.DASHBOARD);
  };

  return (
    <>
      <main className="flex h-dvh flex-col justify-between">
        <img
          src="/images/robot_waving.png"
          alt="Robot Waving"
          className="w-full"
        />
        <div className="flex flex-col gap-4">
          <Typography variant="h5" align="center">
            Great to meet you, {userName}. <br />
            My name is:
          </Typography>
          <Typography variant="h3" align="center">
            Ali
          </Typography>
          <Typography align="center">
            I'm here to help you in your conversation. <br />
            Let's get started! Press "Next" to continue.
          </Typography>
        </div>
        <div className="p-2">
          <Button variant="contained" fullWidth onClick={handleNext}>
            Next
          </Button>
        </div>
      </main>

      {showJoiningActiveSessionDialog && <JoinActiveSessionDialog />}
    </>
  );
}

export default RobotName;
