import { Avatar, Button, Typography } from "@mui/material";
import { useSession } from "../../providers/session";
import { useUserContext } from "../../providers/user";

function JoinSession() {
  const { user } = useUserContext();

  const { activeSession, activeSessionId, joinSession } = useSession();

  const { ownerUser, messages = [] } = activeSession || {};

  if (!ownerUser || !user?.id || !activeSessionId || messages.length > 0)
    return null;

  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <Typography variant="h6" align="center" color="GrayText">
        Session with {ownerUser?.name}
      </Typography>
      <Avatar
        src={ownerUser?.avatar}
        sx={{ height: 80, width: 80 }}
        imgProps={{
          referrerPolicy: "no-referrer",
        }}
      />
      <Typography variant="h6" align="center" color="GrayText">
        Would you like to join this session?
      </Typography>
      <Button
        variant="outlined"
        fullWidth
        size="large"
        onClick={() => joinSession(activeSessionId, user.id)}
      >
        Join Session
      </Button>
    </div>
  );
}

export default JoinSession;
