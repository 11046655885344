import { JoinActiveSessionDialog } from "@/components";
import { ROUTES } from "@/constants/routes";
import { useUserContext } from "@/providers/user";
import { Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { FcGoogle } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router";

function LoginPage() {
  const { loginWithGoogle, isAuthenticated } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  const isJoiningActiveSession = !!location?.state?.from;

  const handleGoogleLogin = async () => {
    await loginWithGoogle();
  };

  useEffect(() => {
    if (isAuthenticated && isMobile && !isJoiningActiveSession) {
      // go to onboarding
      navigate(ROUTES.DASHBOARD);
    }
  }, [isAuthenticated, navigate, isJoiningActiveSession]);

  return (
    <>
      <main className="flex flex-col justify-between h-dvh">
        <div>
          <img
            src="/images/third_party_chat_low_poly.png"
            alt="Pardôn Chat"
            className="w-full"
          />
          <Typography variant="h5" align="center">
            Build your relationship.
            <br />
            One conversation at a time.
          </Typography>
        </div>

        <div className="flex flex-col px-4 pb-4">
          <Button
            onClick={handleGoogleLogin}
            variant="outlined"
            startIcon={<FcGoogle />}
          >
            Sign In with Google
          </Button>
        </div>
      </main>

      {isJoiningActiveSession && isAuthenticated && <JoinActiveSessionDialog />}
    </>
  );
}

export default LoginPage;
