import PageWrapper from "@/components/PageWrapper";
import { db } from "@/config/firebase";
import { useSession } from "@/providers/session";
import { useUserContext } from "@/providers/user";
import { ISessionDTO, IUser, IUserID } from "@/types";
import { snack } from "@/utils/snacks";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Card,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
import { FirebaseError } from "firebase/app";
import {
  Timestamp,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { useState } from "react";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router";
import FindPartnerDialog from "./FindPartnerDialog";

function CreateSessionPage() {
  const { user } = useUserContext();
  const { recentPartners, addUserSession } = useSession();

  const navigate = useNavigate();

  const [selectedPartner, setSelectedPartner] = useState<IUser | null>(null);
  const [showFindPartnerDialog, setShowFindPartnerDialog] = useState(false);
  const [attemptingStartSession, setAttemptingStartSession] = useState(false);
  const [enableTurnBased, setEnableTurnBased] = useState(false);
  // const [optionalGroundRulesEnabled, setOptionalGroundRulesEnabled] = useState<
  //   string[]
  // >([]);

  const handleSelectPartner = (partner: IUser) => {
    // handle selecting a partner
    setSelectedPartner(partner);
    setShowFindPartnerDialog(false);
  };

  const handleStartSession = async () => {
    if (!user || attemptingStartSession) return;

    // create new session in db, and route to session page
    try {
      setAttemptingStartSession(true);

      const participantIds: IUserID[] = [user.id];
      if (selectedPartner) {
        participantIds.push(selectedPartner.id);
      }

      const sessionData: ISessionDTO = {
        participantIds,
        createdAt: serverTimestamp() as Timestamp,
        updatedAt: serverTimestamp() as Timestamp,
        owner: user.id,
        firstTurnVote: {},
        isTurnBased: enableTurnBased,
      };

      // create new session in db
      const createdDoc = await addDoc(collection(db, "sessions"), sessionData);
      const sessionId = createdDoc.id;

      // add session to user's sessions
      await addUserSession(sessionId);

      // route to session page
      navigate(`/session/${sessionId}`);
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
      }
      console.error(error);
      snack.error("Failed to start session.");
    } finally {
      setAttemptingStartSession(false);
    }
  };

  // const handleToggleOptionalGroundRule = (rule: string) => {
  //   if (optionalGroundRulesEnabled.includes(rule)) {
  //     setOptionalGroundRulesEnabled(
  //       optionalGroundRulesEnabled.filter((r) => r !== rule)
  //     );
  //   } else {
  //     setOptionalGroundRulesEnabled([...optionalGroundRulesEnabled, rule]);
  //   }
  // };

  return (
    <PageWrapper>
      <main className="flex flex-1 flex-col justify-between">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            <Typography variant="h4">Create a new session</Typography>

            <Typography>
              Start a new session to begin a conversation with a partner.
            </Typography>
            <Typography>
              The conversation will automatically be moderated by a mediator.
              You can choose to enable mediation rules anytime to ensure that
              your partner is complying with the conversation ground rules.
            </Typography>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <Typography variant="h5" sx={{ mb: 1 }}>
                Recent Partners
              </Typography>

              {recentPartners.length === 0 && (
                <Typography>No recent partners</Typography>
              )}

              {recentPartners
                .filter((partner) => partner.id !== selectedPartner?.id)
                .map((partner) => (
                  <Card
                    key={partner.id}
                    onClick={() => setSelectedPartner(partner)}
                  >
                    <CardHeader
                      avatar={
                        <Avatar src={partner.avatar} alt={partner.name} />
                      }
                      title={partner.name}
                      subheader={partner.email}
                      action={
                        <IconButton onClick={() => setSelectedPartner(partner)}>
                          <MdClose color="white" />
                        </IconButton>
                      }
                    />
                  </Card>
                ))}

              {selectedPartner !== null && (
                <Card sx={{ backgroundColor: "primary.main", color: "white" }}>
                  <CardHeader
                    avatar={
                      <Avatar
                        src={selectedPartner?.avatar}
                        alt={selectedPartner?.name}
                        imgProps={{
                          referrerPolicy: "no-referrer",
                        }}
                      />
                    }
                    title={selectedPartner?.name}
                    subheader={selectedPartner?.email}
                    subheaderTypographyProps={{
                      color: "white",
                    }}
                    action={
                      <IconButton onClick={() => setSelectedPartner(null)}>
                        <MdClose color="white" />
                      </IconButton>
                    }
                  />
                </Card>
              )}
            </div>

            {/* <div>
              <Typography variant="h5">Session Settings</Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Enable turn-based dialog"
                onChange={(e, checked) => setEnableTurnBased(checked)}
                value={enableTurnBased}
              />
            </div> */}

            {/* <div>
              <Typography variant="h5">Ground Rules</Typography>

              <FormGroup>
                {groundRules.required.map((rule) => (
                  <FormControlLabel
                    key={rule}
                    control={<Checkbox defaultChecked />}
                    label={rule}
                    disabled
                  />
                ))}

                {groundRules.optional.map((rule) => (
                  <FormControlLabel
                    key={rule}
                    control={<Checkbox />}
                    label={rule}
                    onChange={() => handleToggleOptionalGroundRule(rule)}
                  />
                ))}
              </FormGroup>
            </div> */}
          </div>
        </div>

        <div>
          {/* find partner */}
          {/* <Button
            variant="outlined"
            fullWidth
            size="large"
            onClick={() => setShowFindPartnerDialog(true)}
          >
            Find Partner
          </Button> */}

          {/* start session */}
          <LoadingButton
            loading={attemptingStartSession}
            variant="outlined"
            disabled={attemptingStartSession}
            fullWidth
            size="large"
            onClick={handleStartSession}
          >
            Start Session
          </LoadingButton>
        </div>

        {/* <ButtonNavigation /> */}
      </main>

      <FindPartnerDialog
        open={showFindPartnerDialog}
        onClose={() => setShowFindPartnerDialog(false)}
        onSelectPartner={handleSelectPartner}
      />
    </PageWrapper>
  );
}

export default CreateSessionPage;
