// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyARXUEtP16vUpLRBfsd4L7TEmCRlYhxJwI",
  authDomain: "third-party-5b3c3.firebaseapp.com",
  projectId: "third-party-5b3c3",
  storageBucket: "third-party-5b3c3.appspot.com",
  messagingSenderId: "921052574196",
  appId: "1:921052574196:web:2113c45901a6f6e67909a1",
  measurementId: "G-HLBJH61Q29",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

const auth = getAuth(app);

export { analytics, app, auth, db };
