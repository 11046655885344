import { ROUTES } from "@/constants/routes";
import { classNames } from "@/utils/styles";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Portal,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { AiOutlineHome } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import {
  MdAddCircleOutline,
  MdLogout,
  MdMenu,
  MdOutlineSettings,
} from "react-icons/md";
import { useNavigate } from "react-router";
import { useUserContext } from "../../providers/user";

function Header({ className }: { className?: string }) {
  const { user, logout, isAuthenticated } = useUserContext();
  const navigate = useNavigate();

  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const [avatarMenuAnchorEl, setAvatarMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const showAvatarMenu = Boolean(avatarMenuAnchorEl);

  const [showMenuDrawer, setShowMenuDrawer] = useState(false);

  const closeMenuDrawer = () => {
    setShowMenuDrawer(false);
  };

  const handleCloseLogoutDialog = () => {
    setShowLogoutDialog(false);
  };

  const openAvatarMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAvatarMenuAnchorEl(event.currentTarget);
  };

  const onCloseAvatarMenu = () => {
    setAvatarMenuAnchorEl(null);
  };

  const handleLogout = async () => {
    await logout();

    setShowLogoutDialog(false);
    navigate(ROUTES.LOGIN);
  };

  const handleOpenLogoutDialog = () => {
    onCloseAvatarMenu();
    setShowLogoutDialog(true);
  };

  const handleHomeClick = () => {
    closeMenuDrawer();

    navigate(ROUTES.DASHBOARD);
  };

  const handleCreateSessionClick = () => {
    closeMenuDrawer();
    navigate(ROUTES.CREATE_SESSION);
  };

  const handleSettingsClick = () => {
    // @TODO add settings route and page
    closeMenuDrawer();
  };

  return (
    <>
      <div
        className={classNames(
          "flex flex-row items-center justify-between px-4 py-2 lg:py-4 shadow-md bg-primary-600 z-10",
          className
        )}
      >
        <div className="flex flex-1 flex-row justify-between">
          <div className="flex flex-row items-center lg:items-start gap-4">
            {isAuthenticated && (
              <IconButton onClick={() => setShowMenuDrawer(true)}>
                <MdMenu color="white" />
              </IconButton>
            )}

            {/* <LogoIcon size={isMobile ? 32 : 40} color={colors.white} /> */}
            <img
              src="/donkey_logo_transparent.png"
              alt="Pardon logo"
              height={50}
            />

            <div className="flex flex-col">
              <Typography
                variant="h5"
                className="text-base !font-normal"
                color="white"
                sx={{
                  pb: 0,
                  mb: 0,
                  lineHeight: 0.8,
                  fontWeight: 700,
                }}
              >
                Donkey Chats
              </Typography>

              <Typography color="white" className="hidden lg:block">
                When you're ready to stop being a{" "}
                <b>
                  <i>donkey</i>
                </b>
                .
              </Typography>
            </div>
          </div>

          {/* {!isAuthenticated && (
            <div className="flex flex-row items-center gap-2">
              <Link to={ROUTES.CHATS_LANDING}>
                <Button
                  variant="outlined"
                  color="secondary"
                  endIcon={<MdChat />}
                >
                  Try the Chats Beta
                </Button>
              </Link>
            </div>
          )} */}
        </div>

        <Drawer open={showMenuDrawer} onClose={() => setShowMenuDrawer(false)}>
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={handleHomeClick}>
                <ListItemIcon>
                  <AiOutlineHome />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton onClick={handleCreateSessionClick}>
                <ListItemIcon>
                  <MdAddCircleOutline />
                </ListItemIcon>
                <ListItemText primary="New Session" />
              </ListItemButton>
            </ListItem>
          </List>

          <div className="fixed left-0 bottom-0">
            <List>
              <ListItem disablePadding>
                {/* @TODO add settings route and page */}
                <ListItemButton onClick={handleSettingsClick}>
                  <ListItemIcon>
                    <MdOutlineSettings />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={handleLogout}>
                  <ListItemIcon>
                    <MdLogout />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItemButton>
              </ListItem>
            </List>
          </div>
        </Drawer>

        {isAuthenticated && (
          <div
            className="cursor-pointer w-[40px] h-[40px] bg-secondary-600 flex items-center justify-center rounded-full"
            onClick={openAvatarMenu}
          >
            {user?.avatar ? (
              <Avatar
                src={user?.avatar}
                alt={user?.name}
                imgProps={{
                  referrerPolicy: "no-referrer",
                }}
              />
            ) : (
              <FaUser size={24} color="white" />
            )}
          </div>
        )}

        <Menu
          open={showAvatarMenu}
          onClose={onCloseAvatarMenu}
          anchorEl={avatarMenuAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <MenuItem>Profile</MenuItem>
          <MenuItem onClick={handleOpenLogoutDialog}>Logout</MenuItem>
        </Menu>
      </div>

      {showLogoutDialog && (
        <Portal>
          <Dialog open={showLogoutDialog} onClose={handleCloseLogoutDialog}>
            <DialogTitle>Are you sure you want to logout?</DialogTitle>
            <DialogContent dividers>
              <Typography>
                Logging out will end your current session.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseLogoutDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleLogout} color="warning">
                Logout
              </Button>
            </DialogActions>
          </Dialog>
        </Portal>
      )}
    </>
  );
}

export default Header;
