import { useSession } from "@/providers/session";
import { useUserContext } from "@/providers/user";
import { IMessage } from "@/types";
import { removeQuotes } from "@/utils/strings";
import { classNames } from "@/utils/styles";
import { Avatar, ClickAwayListener, Tooltip, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import moment from "moment";
import { useEffect, useState } from "react";
import { FaFlag } from "react-icons/fa";

function Message({ message }: { message: IMessage }) {
  const { activeSession } = useSession();
  const { user } = useUserContext();
  const [showFlagTooltip, setShowFlagTooltip] = useState(false);

  const sessionUsers = activeSession?.participantUsers || [];

  const partner = sessionUsers.find(
    (participant) => participant.id !== user?.id
  );

  const isUserMessage = (message: IMessage) => message.senderId === user?.id;

  const messagesLength = activeSession?.messages?.length || 0;

  // scroll to latest message when messages changes
  useEffect(() => {
    const messageList = document.getElementById("message-list");
    if (messageList) {
      // smooth scroll
      messageList.scrollTo({
        top: messageList.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messagesLength]);

  if (!activeSession || !activeSession.messages || !user || !partner)
    return null;

  return (
    <div key={message.id} className="relative px-4">
      <Typography
        textAlign="center"
        color="GrayText"
        className="mb-1 text-center flex items-center justify-center"
        variant="caption"
      >
        {moment(message.createdAt).fromNow()}
      </Typography>

      <div
        className={classNames(
          isUserMessage(message) ? "bg-primary-600" : "bg-gray-200",
          "rounded-md px-4 py-2 relative"
        )}
        key={message.id}
      >
        {message.flagged && (
          <ClickAwayListener onClickAway={() => setShowFlagTooltip(false)}>
            <Tooltip
              open={showFlagTooltip}
              title={
                message.flaggedReason
                  ? removeQuotes(message.flaggedReason)
                  : "Flagged"
              }
              PopperProps={{
                disablePortal: true,
              }}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement="top-start"
            >
              <div
                className="absolute -top-2 right-2"
                onClick={() => setShowFlagTooltip(!showFlagTooltip)}
              >
                <FaFlag color={red[600]} />
              </div>
            </Tooltip>
          </ClickAwayListener>
        )}

        <Typography
          className={classNames(
            isUserMessage(message) ? "text-white" : "text-black",
            "text-sm leading-snug"
          )}
          variant="body1"
        >
          {message.content}
        </Typography>
      </div>

      <div
        className={classNames(
          isUserMessage(message)
            ? "justify-end"
            : " flex-row-reverse justify-start",
          "flex flex-row items-center justify-end gap-2 mt-2"
        )}
      >
        <span className="text-xs">
          {message.senderId === user.id ? "You" : partner.name}
        </span>
        <Avatar
          src={isUserMessage(message) ? user.avatar : partner.avatar}
          alt={isUserMessage(message) ? user.name : partner.name}
          sx={{
            width: 24,
            height: 24,
          }}
          imgProps={{
            referrerPolicy: "no-referrer",
          }}
        />
      </div>
    </div>
  );
}

export default Message;
