function SharedEmpathyIcon() {
  return (
    <svg
      width="64"
      height="65"
      viewBox="0 0 64 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9025 26.6875C14.3667 17.4098 24.615 13.8023 31.3133 23.1441C38.3465 13.48 47.7242 18.312 47.7242 26.6875C47.7242 33.388 36.7836 43.2238 31.3133 47.3042C26.0663 44.2976 15.4383 35.965 14.9025 26.6875Z"
        stroke="#6458A9"
        strokeWidth="4.89231"
        stroke-linejoin="round"
      />
      <path
        d="M32.0462 4.59467C32.0462 4.0597 32.0934 3.51387 31.9465 3"
        stroke="#6458A9"
        strokeWidth="4.89231"
        strokeLinecap="round"
      />
      <path
        d="M32.0462 61.708C32.0462 61.173 32.0934 60.6272 31.9465 60.1133"
        stroke="#6458A9"
        strokeWidth="4.89231"
        strokeLinecap="round"
      />
      <path
        d="M18.1605 8.29067C17.893 7.82736 17.6609 7.33109 17.2768 6.95947"
        stroke="#6458A9"
        strokeWidth="4.89231"
        strokeLinecap="round"
      />
      <path
        d="M46.7169 57.7526C46.4494 57.2893 46.2173 56.793 45.8332 56.4214"
        stroke="#6458A9"
        strokeWidth="4.89231"
        strokeLinecap="round"
      />
      <path
        d="M7.99908 18.407C7.53609 18.139 7.08733 17.8247 6.56905 17.6943"
        stroke="#6458A9"
        strokeWidth="4.89231"
        strokeLinecap="round"
      />
      <path
        d="M57.4273 47.0203C56.9643 46.7523 56.5155 46.438 55.9973 46.3076"
        stroke="#6458A9"
        strokeWidth="4.89231"
        strokeLinecap="round"
      />
      <path
        d="M4.24099 32.2596C3.70602 32.2586 3.16028 32.2105 2.64615 32.3564"
        stroke="#6458A9"
        strokeWidth="4.89231"
        strokeLinecap="round"
      />
      <path
        d="M61.3537 32.3592C60.8188 32.3582 60.273 32.3101 59.7589 32.4561"
        stroke="#6458A9"
        strokeWidth="4.89231"
        strokeLinecap="round"
      />
      <path
        d="M7.86241 46.0635C7.39767 46.3285 6.90014 46.5579 6.52646 46.9399"
        stroke="#6458A9"
        strokeWidth="4.89231"
        strokeLinecap="round"
      />
      <path
        d="M57.4771 17.7744C57.0124 18.0394 56.5148 18.2688 56.1411 18.6509"
        stroke="#6458A9"
        strokeWidth="4.89231"
        strokeLinecap="round"
      />
      <path
        d="M18.085 56.3747C17.8176 56.838 17.504 57.2872 17.3743 57.8057"
        stroke="#6458A9"
        strokeWidth="4.89231"
        strokeLinecap="round"
      />
      <path
        d="M46.632 6.90738C46.3646 7.37074 46.0509 7.81992 45.9212 8.33838"
        stroke="#6458A9"
        strokeWidth="4.89231"
        strokeLinecap="round"
      />
      <path
        d="M18.1605 8.29067C17.893 7.82736 17.6609 7.33109 17.2768 6.95947"
        stroke="#6458A9"
        strokeWidth="4.89231"
        strokeLinecap="round"
      />
      <path
        d="M46.7169 57.7526C46.4494 57.2893 46.2173 56.793 45.8332 56.4214"
        stroke="#6458A9"
        strokeWidth="4.89231"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SharedEmpathyIcon;
