export function copyLink(link: string) {
  if (navigator.clipboard) {
    // Modern browsers (not including Safari)
    navigator.clipboard.writeText(link);
  } else {
    // Safari and others
    var textArea = document.createElement("textarea");
    textArea.value = link;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  }
}
