import { Button, LinearProgress, List, Typography } from "@mui/material";

import PageWrapper from "@/components/PageWrapper";
import { useSession } from "@/providers/session";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useUserContext } from "../../providers/user";
import ActiveSessionItem from "./ActiveSessionItem";
import SessionInviteDialog from "./SessionInviteDialog";

function DashboardPage() {
  const { user } = useUserContext();
  const {
    sessions,
    mostRecentInvitedSession,
    loadingSessions,
    activeSessions,
  } = useSession();

  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [showArchivedSessions, setShowArchivedSessions] = useState(false);

  const userName = user?.name || "User";

  const archivedSessions = sessions.filter((session) => session.archivedAt);

  const toggleArchivedSessions = () => {
    setShowArchivedSessions((prev) => !prev);
  };

  useEffect(() => {
    if (mostRecentInvitedSession) {
      setShowInviteDialog(true);
    }
  }, [mostRecentInvitedSession]);

  return (
    <>
      <PageWrapper className="p-0">
        <div className="flex flex-1 flex-col gap-4 justify-between h-full">
          <div className="flex flex-col max-h-[calc(100%-4rem)]">
            <div className="mb-2 flex flex-col gap-2 p-4 pb-0">
              <Typography variant="h5">Hi {userName} 👋</Typography>
              <Typography>
                Welcome to Pardôn, a place where you can share your thoughts and
                feelings openly with one another through mediatiated dialog.
              </Typography>
            </div>

            <div className="flex flex-1 flex-col mt-4 max-h-full overflow-y-scroll">
              <div className="px-4">
                <Typography variant="h5">Active Sessions</Typography>

                {archivedSessions.length > 0 && (
                  <Button
                    sx={{ ml: -1 }}
                    variant="text"
                    onClick={toggleArchivedSessions}
                  >
                    {showArchivedSessions ? "Hide" : "Show"} archived sessions
                  </Button>
                )}
              </div>

              {loadingSessions ? (
                <div className="py-2">
                  <LinearProgress color="primary" />
                </div>
              ) : (
                <>
                  {activeSessions.length === 0 && (
                    <div className="px-4">
                      <Typography>No past sessions</Typography>
                    </div>
                  )}

                  {activeSessions.length > 0 && (
                    <List dense>
                      {sessions
                        // filter out archived sessions
                        .filter((session) => {
                          if (showArchivedSessions && !!session.archivedAt) {
                            return true;
                          }

                          if (!showArchivedSessions && session.archivedAt) {
                            return false;
                          }

                          return true;
                        })
                        .map((session) => (
                          <ActiveSessionItem
                            showMenu={!session.archivedAt}
                            key={session.id}
                            session={session}
                          />
                        ))}
                    </List>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="px-4 pb-4">
            <Link to={ROUTES.CREATE_SESSION}>
              <Button variant="outlined" fullWidth size="large">
                Start New Session
              </Button>
            </Link>
          </div>
        </div>
      </PageWrapper>

      {showInviteDialog && (
        <SessionInviteDialog onClose={() => setShowInviteDialog(false)} />
      )}
    </>
  );
}

export default DashboardPage;
