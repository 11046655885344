import { FirebaseError } from "firebase/app";
import { doc, getDoc, setDoc } from "firebase/firestore";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { db } from "../config/firebase";
import { OnboardingStep } from "../types/onboarding";
import { useUserContext } from "./user";

interface OnboardingContextValue {
  onboarding: OnboardingStep[];
  updateOnboarding: (step: OnboardingStep) => Promise<void>;
  onboardingComplete: boolean | null;
}

// Define the initial onboarding steps
const initialOnboarding: OnboardingStep[] = [];

// Create the context
export const OnboardingContext = createContext<OnboardingContextValue>({
  onboarding: initialOnboarding,
  updateOnboarding: async () => {},
  onboardingComplete: null,
});

// hook
export const useOnboarding = () => {
  const onboarding = React.useContext(OnboardingContext);
  if (!onboarding) {
    throw new Error(
      "useOnboardingContext must be used within an OnboardingProvider"
    );
  }
  return onboarding;
};

enum LoadingOnboardingStatus {
  Idle = "Idle",
  Loading = "Loading",
  Loaded = "Loaded",
  Error = "Error",
}

// Create the provider component
export const OnboardingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user, isAuthenticated } = useUserContext();

  const [loadingOnboardingStatus, setLoadingOnboardingStatus] =
    useState<LoadingOnboardingStatus>(LoadingOnboardingStatus.Idle);
  const [onboarding, setOnboarding] =
    useState<OnboardingStep[]>(initialOnboarding);

  // Load onboarding progress from db
  const loadOnboarding = useCallback(async () => {
    if (!user) {
      throw new Error("User is not defined");
    }

    setLoadingOnboardingStatus(LoadingOnboardingStatus.Loading);

    try {
      const docRef = doc(db, "onboarding", user.id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data) {
          setOnboarding(data.steps);
        }
      }

      setLoadingOnboardingStatus(LoadingOnboardingStatus.Loaded);
    } catch (error) {
      setLoadingOnboardingStatus(LoadingOnboardingStatus.Error);

      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
      }
    }
  }, [user]);

  // Function to update the onboarding progress
  const updateOnboarding = async (step: OnboardingStep) => {
    if (!user) {
      throw new Error("User is not defined");
    }

    // do not add duplicate steps
    if (onboarding.includes(step)) return;

    const updatedSteps = [...onboarding, step];
    setOnboarding(updatedSteps);

    // update in db
    try {
      const docRef = doc(db, "onboarding", user.id);
      await setDoc(docRef, { steps: updatedSteps });
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
      }
    }
  };

  // Check if onboarding is complete
  const onboardingComplete = useMemo(() => {
    if (!isAuthenticated) return null;

    if (
      loadingOnboardingStatus === LoadingOnboardingStatus.Loading ||
      loadingOnboardingStatus === LoadingOnboardingStatus.Idle
    ) {
      return null;
    }

    return onboarding.length === Object.keys(OnboardingStep).length;
  }, [isAuthenticated, loadingOnboardingStatus, onboarding.length]);

  useEffect(() => {
    if (isAuthenticated) {
      loadOnboarding();
    }
  }, [loadOnboarding, isAuthenticated]);

  return (
    <OnboardingContext.Provider
      value={{
        onboarding,
        updateOnboarding,
        onboardingComplete,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
