import { useUserContext } from "@/providers/user";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Portal,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router";

function JoinActiveSessionDialog() {
  const { isAuthenticated, loginWithGoogle } = useUserContext();

  const navigate = useNavigate();
  const location = useLocation();

  const handleJoinSession = async () => {
    if (!isAuthenticated) {
      await loginWithGoogle();
    }

    navigate(location?.state?.from);
  };

  return (
    <Portal>
      <Dialog open={true}>
        <DialogTitle>Join Session</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            You are about to join an active session. Click "Join" to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => navigate("/dashboard")}
            color="warning"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={handleJoinSession}
            color="primary"
            variant="contained"
          >
            Join
          </Button>
        </DialogActions>
      </Dialog>
    </Portal>
  );
}

export default JoinActiveSessionDialog;
