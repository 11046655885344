export const ROUTES = {
  DASHBOARD: "/dashboard",
  CREATE_NAME: "/create-name",
  CREATE_SESSION: "/create-session",
  LANDING: "/",
  CHATS_LANDING: "/chats-landing",
  LOGIN: "/login",
  ROBOT_NAME: "/robot-name",
  SESSION: "/session",
  SIGNUP: "/signup",
  NOT_FOUND: "*",
};
