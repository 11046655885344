import Header from "@/components/Header";
import {
  BuildRelationshipsIcon,
  DataProtectedIcon,
  SharedEmpathyIcon,
} from "@/components/Icons";
import LoadingUserDialog from "@/components/LoadingUserDialog";
import { useUserContext } from "@/providers/user";
import { copyLink } from "@/utils/copy";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Portal,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { FaChevronLeft, FaRegClipboard } from "react-icons/fa";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link, useNavigate } from "react-router-dom";
import colors from "tailwindcss/colors";
import { ROUTES } from "../../constants/routes";
import "./styles.css";

const featuresList: {
  title: string;
  description: string | React.ReactNode;
  icon: React.ReactNode;
}[] = [
  {
    title: "Find Shared Empathy",
    description:
      "Improve your relationship by seeing from your partner's perspective.",
    icon: <SharedEmpathyIcon />,
  },
  {
    title: "Easy to Use",
    description:
      "Say what you need to say while strengthening relationships with this easy-to-use tool.",
    icon: <BuildRelationshipsIcon />,
  },
  {
    title: "Data Protection",
    description: "Your data is protected and never shared with anyone.",
    icon: <DataProtectedIcon />,
  },
];

function ChatsLandingPage() {
  const navigate = useNavigate();

  const { isAuthenticated } = useUserContext();
  const [showLoadingUserDialog, setShowLoadingUserDialog] = useState(false);
  const [showNonMobileWarning, setShowNonMobileWarning] = useState(false);

  const handleCopyLink = () => {
    copyLink(window.location.href);

    enqueueSnackbar("Link copied to clipboard", {
      variant: "success",
      autoHideDuration: 1500,
      style: { backgroundColor: colors.purple[800], color: "white" },
    });
  };

  useEffect(() => {
    if (!isMobile) {
      setShowNonMobileWarning(true);
    }
  }, []);

  useEffect(() => {
    // redirect to dashboard if user is authenticated
    if (isAuthenticated) {
      navigate(ROUTES.DASHBOARD);
      return;
    }
  }, [isAuthenticated, navigate]);

  if (!isMobile) {
    return (
      <>
        <main className="flex flex-col justify-between h-dvh">
          <Header />
        </main>

        {showNonMobileWarning && (
          <Portal>
            <Dialog open={showNonMobileWarning}>
              <DialogTitle textAlign="center">Mobile Only</DialogTitle>
              <DialogContent dividers>
                <div className="flex flex-col justify-center items-center">
                  <Typography>
                    This app is only designed for mobile devices. Please visit
                    on your phone.
                  </Typography>
                </div>
              </DialogContent>
              <DialogActions>
                <Link to={ROUTES.LANDING}>
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<FaChevronLeft />}
                  >
                    Go Back
                  </Button>
                </Link>
                <Button
                  onClick={handleCopyLink}
                  variant="outlined"
                  endIcon={<FaRegClipboard />}
                >
                  Copy Link
                </Button>
              </DialogActions>
            </Dialog>
          </Portal>
        )}
      </>
    );
  }

  return (
    <>
      <main className="flex flex-col justify-between h-dvh">
        <Typography variant="h3" align="center" className="pt-12">
          Pardôn?
        </Typography>

        <div className="mt-4">
          <Typography variant="h6" align="center" className="font-light">
            Build your relationship. <br />
            One conversation at a time.
          </Typography>
        </div>

        <div className="p-4 [&_.control-dots]:flex-row [&_.control-dots]:flex [&_.control-dots]:gap-1 [&_.control-dots]">
          <Carousel
            showStatus={false}
            renderIndicator={(onClick, isSelected, index, label) => {
              if (isSelected) {
                return (
                  <li
                    className="w-1/3 h-1 bg-purple-800"
                    onClick={onClick}
                    key={index}
                  />
                );
              }

              return (
                <li
                  className="w-1/3 h-1 bg-purple-200"
                  onClick={onClick}
                  key={index}
                />
              );
            }}
          >
            {featuresList.map((feature) => (
              <div key={feature.title} className="mb-4">
                <div className="flex flex-col justify-center items-center">
                  <div className="rounded-full p-4 m-2 mb-0 flex items-center justify-center">
                    {feature.icon}
                  </div>

                  <div className="px-2 pb-6 flex flex-col">
                    <Typography variant="h5" className="pt-2">
                      {feature.title}
                    </Typography>
                    <Typography variant="body1" className="pb-2">
                      {feature.description}
                    </Typography>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>

        <div className="flex flex-col px-4 pb-4">
          <Link to={ROUTES.LOGIN}>
            <Button variant="outlined" color="primary" href="/login" fullWidth>
              Get Started
            </Button>
          </Link>
        </div>
      </main>

      {showLoadingUserDialog && (
        <LoadingUserDialog onClose={() => setShowLoadingUserDialog(false)} />
      )}
    </>
  );
}

export default ChatsLandingPage;
