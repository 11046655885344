import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Portal,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { SnackbarProvider } from "notistack";
import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./config/firebase";
import { ROUTES } from "./constants/routes";
import ActiveSessionPage from "./pages/ActiveSession";
import ChatsLandingPage from "./pages/ChatsLanding";
import CreateSessionPage from "./pages/CreateSession";
import DashboardPage from "./pages/Dashboard";
import LandingPage from "./pages/Landing";
import LoginPage from "./pages/Login";
import RobotNamePage from "./pages/RobotName";
import { OnboardingProvider } from "./providers/onboarding";
import { SessionProvider } from "./providers/session";
import UserProvider from "./providers/user";
import theme from "./theme";

function App() {
  const [showNonMobileWarning, setShowNonMobileWarning] = useState(false);

  // useEffect(() => {
  //   if (!isMobile) {
  //     setShowNonMobileWarning(true);
  //   }
  // }, []);

  return (
    <BrowserRouter>
      <UserProvider>
        <SessionProvider>
          <OnboardingProvider>
            <ThemeProvider theme={theme}>
              <SnackbarProvider className="">
                <Routes>
                  <Route path={ROUTES.LANDING} element={<LandingPage />} />
                  <Route
                    path={ROUTES.CHATS_LANDING}
                    element={<ChatsLandingPage />}
                  />
                  <Route path={ROUTES.LOGIN} element={<LoginPage />} />
                  <Route path={ROUTES.SIGNUP} element={<LoginPage />} />
                  <Route path={ROUTES.ROBOT_NAME} element={<RobotNamePage />} />
                  <Route path={ROUTES.DASHBOARD} element={<DashboardPage />} />
                  <Route
                    path={ROUTES.CREATE_SESSION}
                    element={<CreateSessionPage />}
                  />
                  <Route
                    path={`${ROUTES.SESSION}/:id`}
                    element={<ActiveSessionPage />}
                  />

                  <Route
                    path={ROUTES.NOT_FOUND}
                    element={<div>Not Found</div>}
                  />
                </Routes>

                {showNonMobileWarning && (
                  <Portal>
                    <Dialog
                      open={showNonMobileWarning}
                      onClose={() => setShowNonMobileWarning(false)}
                    >
                      <DialogTitle textAlign="center">Mobile Only</DialogTitle>
                      <DialogContent dividers>
                        <div className="flex flex-col justify-center items-center">
                          <Typography>
                            This app is only designed for mobile devices.
                          </Typography>
                          <Typography>Proceed with caution.</Typography>
                          😈
                        </div>
                      </DialogContent>
                      <DialogActions
                        sx={{
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={() => setShowNonMobileWarning(false)}
                        >
                          Ok
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Portal>
                )}
              </SnackbarProvider>
            </ThemeProvider>
          </OnboardingProvider>
        </SessionProvider>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
