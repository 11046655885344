function DataProtectedIcon() {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_109_52)">
        <path
          d="M44.9041 5.17176L29.7637 0.123344C29.2679 -0.0417656 28.732 -0.0417656 28.2363 0.123344L13.0959 5.17176C10.6887 5.97139 8.59465 7.5093 7.11137 9.56698C5.62809 11.6247 4.83099 14.0974 4.83332 16.634V29.0001C4.83332 47.2773 27.0667 57.3718 28.0188 57.7923C28.3277 57.9295 28.662 58.0004 29 58.0004C29.338 58.0004 29.6723 57.9295 29.9812 57.7923C30.9333 57.3718 53.1667 47.2773 53.1667 29.0001V16.634C53.169 14.0974 52.3719 11.6247 50.8886 9.56698C49.4053 7.5093 47.3113 5.97139 44.9041 5.17176ZM48.3333 29.0001C48.3333 42.183 33.0624 50.8298 29 52.8985C24.9327 50.8371 9.66665 42.2168 9.66665 29.0001V16.634C9.66679 15.1122 10.1458 13.629 11.0359 12.3946C11.9259 11.1601 13.1818 10.2371 14.6257 9.75618L29 4.96393L43.3743 9.75618C44.8182 10.2371 46.0741 11.1601 46.9641 12.3946C47.8542 13.629 48.3332 15.1122 48.3333 16.634V29.0001Z"
          fill="#6458A9"
        />
        <path
          d="M36.975 20.0583L26.854 30.2083L21.431 24.5533C21.2134 24.3153 20.9503 24.1233 20.6574 23.9886C20.3644 23.8539 20.0474 23.7792 19.7251 23.7688C19.4028 23.7585 19.0817 23.8128 18.7807 23.9285C18.4797 24.0442 18.2049 24.219 17.9725 24.4425C17.7401 24.6661 17.5547 24.9339 17.4274 25.2301C17.3001 25.5264 17.2334 25.8452 17.2311 26.1676C17.2289 26.4901 17.2912 26.8097 17.4145 27.1077C17.5377 27.4057 17.7193 27.676 17.9486 27.9028L23.5214 33.7028C23.9371 34.1517 24.4394 34.5116 24.9981 34.7608C25.5568 35.0101 26.1602 35.1434 26.7719 35.1528H26.8516C27.4511 35.1547 28.045 35.0376 28.5988 34.8082C29.1526 34.5788 29.6554 34.2417 30.0779 33.8164L40.4019 23.4924C40.6273 23.2673 40.8063 23.0001 40.9285 22.706C41.0507 22.4118 41.1138 22.0964 41.1141 21.7779C41.1145 21.4593 41.0521 21.1438 40.9305 20.8494C40.8089 20.555 40.6305 20.2874 40.4055 20.0619C40.1805 19.8364 39.9133 19.6575 39.6191 19.5352C39.3249 19.413 39.0096 19.3499 38.691 19.3496C38.3725 19.3493 38.057 19.4117 37.7625 19.5333C37.4681 19.6549 37.2005 19.8333 36.975 20.0583Z"
          fill="#6458A9"
        />
      </g>
      <defs>
        <clipPath id="clip0_109_52">
          <rect width="58" height="58" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DataProtectedIcon;
