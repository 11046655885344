import { ROUTES } from "@/constants/routes";
import { snack } from "@/utils/snacks";
import {
  DocumentData,
  QueryDocumentSnapshot,
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router";
import { db } from "../config/firebase";
import {
  IMessage,
  IMessageDTO,
  ISession,
  ISessionDTO,
  ISessionID,
  IUser,
  IUserID,
} from "../types";
import { useUserContext } from "./user";

interface ActiveSession extends ISession {
  ownerUser: IUser | null;
  participantUsers: IUser[];
}

// Define the shape of the context
interface SessionContextProps {
  activeSessionId: ISessionID | null;
  setActiveSessionId: (sessionId: ISessionID) => void;
  activeSession: ActiveSession | null;
  setActiveSession: (session: ActiveSession | null) => void;
  mostRecentInvitedSession: ISession | null;
  sessions: ISession[];
  activeSessions: ISession[]; // sessions that are not archived
  loadingSessions: boolean;
  getPartner: (partnerId: IUserID) => IUser | null;
  recentPartners: IUser[];
  joinSession: (sessionId: ISessionID, partnerId: IUserID) => Promise<void>;
  archiveSession: (sessionId: ISessionID) => Promise<void>;
  recentSessions: ISession[];
  requireUserMediation: (
    sessionId: ISessionID,
    partnerId: IUserID
  ) => Promise<void>;
  removeUserMediation: (
    sessionId: ISessionID,
    partnerId: IUserID
  ) => Promise<void>;
  addUserAcknowledgedMediation: (
    sessionId: ISessionID,
    partnerId: IUserID
  ) => Promise<void>;
  addUserSession: (sessionId: ISessionID) => Promise<void>;
}

// Create the context
const SessionContext = createContext<SessionContextProps>({
  activeSessionId: null,
  setActiveSessionId: () => {},
  activeSession: null,
  setActiveSession: () => {},
  mostRecentInvitedSession: null,
  sessions: [],
  activeSessions: [],
  loadingSessions: false,
  getPartner: () => null,
  recentPartners: [],
  joinSession: async () => {},
  archiveSession: async () => {},
  recentSessions: [],
  requireUserMediation: async () => {},
  removeUserMediation: async () => {},
  addUserAcknowledgedMediation: async () => {},
  addUserSession: async () => {},
});

// Create a hook to use the session context
export const useSession = () => {
  const context = React.useContext(SessionContext);
  if (!context) {
    throw new Error("useSessionContext must be used within a SessionProvider");
  }
  return context;
};

// Create the provider component
export const SessionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { pathname } = useLocation();

  const { user } = useUserContext();

  const [activeSessionId, setActiveSessionId] = useState<ISessionID | null>(
    null
  );
  const [activeSession, setActiveSession] = useState<ActiveSession | null>(
    null
  );
  const [loadingSessions, setLoadingSessions] = useState(true);

  const [sessions, setSessions] = useState<ISession[]>([]);

  const [partnersMap, setPartnersMap] = useState<Record<string, IUser>>({});

  // watch for changes to activeSessionId
  // and fetch the session data
  useEffect(() => {
    if (!activeSessionId) return;

    // get initial session data
    // loadInitialSessionData();

    const unsubscribe = onSnapshot(
      doc(db, "sessions", activeSessionId),
      async (sessionDoc) => {
        const session = sessionDoc.data() as ISessionDTO | undefined;

        if (!session) {
          // clear all session data
          setActiveSession(null);
          setActiveSessionId(null);
          setSessions([]);
          setPartnersMap({});

          snack.error("No matching session.");
          return;
        }

        // get owner data
        const ownerId = session.owner;
        const ownerUser = await getDoc(doc(db, "users", ownerId));
        const owner = ownerUser.data() as IUser | undefined;

        if (!owner) {
          snack.error("Owner not found");
          return;
        }

        // get participant data
        const participantIds = session.participantIds;
        const participantUsers: IUser[] = [];

        for (const userId of participantIds) {
          const participantUser = await getDoc(doc(db, "users", userId));
          const participant = participantUser.data() as IUser | undefined;

          if (participant) {
            participantUsers.push(participant);
          }
        }

        // get all messages from "messages" subcollection on "sessions" document
        const sessionRef = doc(db, "sessions", activeSessionId);
        const messagesRef = collection(sessionRef, "messages");

        // get all messages from the subcollection
        const messagesSnapshot = await getDocs(messagesRef);
        const messages = messagesSnapshot.docs
          .map((doc) => {
            const data = doc.data() as IMessageDTO;
            return {
              ...data,
              id: doc.id,
              createdAt:
                data && data.createdAt && data.createdAt.toDate
                  ? data.createdAt.toDate()
                  : null,
            } as IMessage;
          })
          .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());

        setActiveSession({
          ...session,
          id: sessionDoc.id,
          ownerUser: owner || null,
          participantUsers,
          messages,
          createdAt: session.createdAt.toDate(),
          updatedAt: session.updatedAt.toDate(),
          endedAt: session?.endedAt?.toDate() || undefined,
        });

        setActiveSessionId(sessionDoc.id);
      }
    );

    return () => unsubscribe();
  }, [activeSessionId]);

  // watch for messages on the active session
  useEffect(() => {
    if (!activeSession) return;

    const sessionRef = doc(db, "sessions", activeSession.id);
    const messagesRef = collection(sessionRef, "messages");

    const unsubscribe = onSnapshot(messagesRef, async (messagesSnapshot) => {
      const messages = messagesSnapshot.docs
        .map((doc) => {
          const data = doc.data() as IMessageDTO;
          return {
            ...data,
            id: doc.id,
            createdAt:
              data && data.createdAt && data.createdAt.toDate
                ? data.createdAt.toDate()
                : null,
          } as IMessage;
        })
        .sort((a, b) => a.createdAt?.getTime() - b.createdAt?.getTime());

      setActiveSession((prevSession) => {
        if (!prevSession) return null;

        return {
          ...prevSession,
          messages,
        };
      });
    });

    return () => unsubscribe();
  }, [activeSession]);

  const addUserSession = async (sessionId: ISessionID) => {
    if (!user) return;

    // add session to "userSessions" collection in "sessionIds" array
    const userSessionsRef = collection(db, "userSessions");
    const userSessionsDoc = await getDoc(doc(userSessionsRef, user.id));
    if (!userSessionsDoc.exists()) {
      // create new userSessions doc
      await addDoc(userSessionsRef, {
        sessionIds: [sessionId],
        userId: user.id,
      });
    } else {
      await updateDoc(doc(userSessionsRef, user.id), {
        sessionIds: [...(userSessionsDoc.data().sessionIds || []), sessionId],
      });
    }
  };

  const joinSession = async (sessionId: ISessionID, partnerId: IUserID) => {
    if (!sessionId || !partnerId) return;

    // implement join session
    // add user to session participants
    const sessionRef = doc(db, "sessions", sessionId);
    const sessionDoc = await getDoc(sessionRef);
    const session = sessionDoc.data() as ISession | undefined;

    if (!session) {
      snack.error("No matching session.");
      return;
    }

    // update the session with the new participant if
    // the user is not already a participant
    const participantIds = session.participantIds || [];
    if (!participantIds.includes(partnerId)) {
      participantIds.push(partnerId);
    }

    // update the session with acceptedBy
    const acceptedBy = session.acceptedBy || [];
    if (!acceptedBy.includes(partnerId)) {
      acceptedBy.push(partnerId);
    }

    await setDoc(sessionRef, {
      ...session,
      participantIds,
      acceptedBy,
    });

    // update the userSessions document for the user
    await addUserSession(sessionId);
  };

  const fetchSessions = useCallback(
    async (docSnapshot: QueryDocumentSnapshot<DocumentData, DocumentData>) => {
      setLoadingSessions(true);

      const data = docSnapshot.data();
      const sessionIds = data.sessionIds as string[];

      // Now, for each sessionId, we can fetch the corresponding session
      // and listen for changes on it
      sessionIds.forEach((sessionId) => {
        const sessionRef = doc(db, "sessions", sessionId);
        onSnapshot(sessionRef, async (sessionSnapshot) => {
          if (sessionSnapshot.exists()) {
            const sessionData = sessionSnapshot.data() as ISessionDTO;

            // get messages from the subcollection
            const messagesRef = collection(sessionRef, "messages");

            // Listen for changes in the 'messages' collection
            onSnapshot(messagesRef, (snapshot) => {
              const updatedMessages = snapshot
                .docChanges()
                .map((change) => {
                  const data = change.doc.data() as IMessageDTO;
                  const message = {
                    ...data,
                    id: change.doc.id,
                    createdAt:
                      data && data.createdAt && data.createdAt.toDate
                        ? data.createdAt.toDate()
                        : null,
                  } as IMessage;

                  // Sort messages by createdAt
                  return message;
                })
                .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());

              // Update the session with the new messages
              const updatedSession: ISession = {
                ...sessionData,
                createdAt: sessionData.createdAt.toDate(),
                updatedAt: sessionData.updatedAt.toDate(),
                endedAt: sessionData.endedAt?.toDate() || undefined,
                id: sessionId,
                messages: updatedMessages,
              };

              // only include sessions that have at least 2 participants
              if (updatedSession.participantIds.length < 2) return;

              // store the session data in the sessions array
              setSessions((prevSessions) => {
                // update the session if it already exists
                if (prevSessions.find((s) => s.id === sessionId)) {
                  return prevSessions.map((s) =>
                    s.id === sessionId ? updatedSession : s
                  );
                } else {
                  // add the session if it does not exist
                  return [...prevSessions, updatedSession];
                }
              });
            });

            // Remember to unsubscribe from your snapshot listener when you no longer need it
            // For example, when your component unmounts
            // unsubscribe();

            setLoadingSessions(false);
          }
        });
      });
    },
    [setSessions]
  );

  // watch userSessions collection for changes
  useEffect(() => {
    if (!user) return;

    // Get reference to the userSessions document for the current user
    const q = query(
      collection(db, "userSessions"),
      where("userId", "==", user.id)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((docSnapshot) => {
        if (!docSnapshot.exists()) return;

        fetchSessions(docSnapshot);
      });
    });

    return () => unsubscribe();
  }, [fetchSessions, user]);

  // get all partners associated with the active sessions
  const fetchPartner = async (partnerId: string) => {
    const partnerDoc = await getDoc(doc(db, "users", partnerId));
    const partner = partnerDoc.data() as IUser | undefined;

    if (!partner) {
      snack.error("Partner not found");
      return;
    }

    setPartnersMap((prevMap) => ({
      ...prevMap,
      [partnerId]: partner,
    }));
  };

  // set archive date for session
  const archiveSession = async (sessionId: ISessionID) => {
    if (!sessionId) return;

    try {
      const sessionRef = doc(db, "sessions", sessionId);

      // get the session data
      const sessionDoc = await getDoc(sessionRef);
      const session = sessionDoc.data() as ISession | undefined;

      if (!session) {
        snack.error("No matching session.");
        return;
      }

      session.archivedAt = serverTimestamp() as Timestamp;
      await setDoc(sessionRef, session);
    } catch (error) {
      console.error("Failed to archive session", error);
      snack.error("Failed to archive session");
    }
  };

  const getPartner = (partnerId: string) => {
    return partnersMap[partnerId] || null;
  };

  const requireUserMediation = async (
    sessionId: ISessionID,
    partnerId: IUserID
  ) => {
    if (!sessionId || !partnerId) return;

    // implement require user mediation
    // add user to session participants
    const sessionRef = doc(db, "sessions", sessionId);
    const sessionDoc = await getDoc(sessionRef);
    const session = sessionDoc.data() as ISession | undefined;

    if (!session) {
      snack.error("No matching session.");
      return;
    }

    // update the required mediation for the user
    const usersRequiringMediation = session.usersRequiringMediation || [];
    if (!usersRequiringMediation.includes(partnerId)) {
      usersRequiringMediation.push(partnerId);
    }

    await setDoc(sessionRef, {
      ...session,
      usersRequiringMediation,
    });
  };

  const removeUserMediation = async (
    sessionId: ISessionID,
    partnerId: IUserID
  ) => {
    if (!sessionId || !partnerId) return;

    // implement remove user mediation
    // add user to session participants
    const sessionRef = doc(db, "sessions", sessionId);
    const sessionDoc = await getDoc(sessionRef);
    const session = sessionDoc.data() as ISession | undefined;

    if (!session) {
      snack.error("No matching session.");
      return;
    }

    // update the required mediation for the user
    const usersRequiringMediation = session.usersRequiringMediation || [];
    const updatedUsersRequiringMediation = usersRequiringMediation.filter(
      (id) => id !== partnerId
    );

    await setDoc(sessionRef, {
      ...session,
      usersRequiringMediation: updatedUsersRequiringMediation,
    });
  };

  const addUserAcknowledgedMediation = async (
    sessionId: ISessionID,
    partnerId: IUserID
  ) => {
    if (!sessionId || !partnerId) return;

    // implement remove user mediation
    // add user to session participants
    const sessionRef = doc(db, "sessions", sessionId);
    const sessionDoc = await getDoc(sessionRef);
    const session = sessionDoc.data() as ISession | undefined;

    if (!session) {
      snack.error("No matching session.");
      return;
    }

    // update the required mediation for the user
    const usersAcknowledgedMediation = session.usersAcknowledgedMediation || [];
    if (!usersAcknowledgedMediation.includes(partnerId)) {
      usersAcknowledgedMediation.push(partnerId);
    }

    await setDoc(sessionRef, {
      ...session,
      usersAcknowledgedMediation,
    });
  };

  const mostRecentInvitedSession = useMemo(() => {
    if (!user) return null;

    const sortedSessions = sessions
      // filter out sessions that the user is not an owner
      .filter((session) => session.owner !== user.id)
      // filter out sessions the user has declined or accepted
      .filter(
        (session) =>
          !session.acceptedBy?.includes(user.id) &&
          !session.declinedBy?.includes(user.id)
      )
      // filter out any sessions that were created over an hour ago
      .filter(
        (session) =>
          session.createdAt.getTime() + 60 * 60 * 1000 > new Date().getTime()
      )
      // sort sessions by createdAt date
      .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

    return sortedSessions[0] || null;
  }, [sessions, user]);

  const recentSessions = useMemo(() => {
    if (!user) return [];

    return (
      sessions
        .filter((session) => session.owner !== user.id)
        // remove duplicates by session id
        .filter(
          (session, index, self) =>
            index === self.findIndex((s) => s.id === session.id)
        )
        .sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime())
    );
  }, [sessions, user]);

  useEffect(() => {
    if (!user) return;

    const partnerIds = sessions.reduce((acc, curr) => {
      const ids = curr.participantIds.filter((id) => id !== user?.id);
      return [...acc, ...ids];
    }, [] as string[]);

    // only fetch partners that are not already in the partners array
    const newPartners = partnerIds.filter((id) => {
      return !partnersMap[id];
    });

    // fetch the new partners
    newPartners.forEach(fetchPartner);
  }, [sessions, partnersMap, user]);

  // clear out active session data when the user navigates away from the active session
  useEffect(() => {
    if (!pathname.includes(ROUTES.SESSION)) {
      setActiveSession(null);
      setActiveSessionId(null);
    }
  }, [pathname]);

  const activeSessions = sessions.filter((session) => !session.archivedAt);

  return (
    <SessionContext.Provider
      value={{
        activeSessionId,
        setActiveSessionId,
        activeSession,
        setActiveSession,
        mostRecentInvitedSession: mostRecentInvitedSession,
        sessions,
        activeSessions,
        loadingSessions,
        getPartner,
        recentPartners: Object.values(partnersMap),
        joinSession,
        archiveSession,
        recentSessions,
        requireUserMediation,
        removeUserMediation,
        addUserAcknowledgedMediation,
        addUserSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
