import { ROUTES } from "@/constants/routes";
import { useSession } from "@/providers/session";
import { useUserContext } from "@/providers/user";
import { ISession } from "@/types";
import { snack } from "@/utils/snacks";
import { classNames } from "@/utils/styles";
import {
  Avatar,
  IconButton,
  ListItemAvatar,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { FaArchive, FaUser } from "react-icons/fa";
import { GoKebabHorizontal } from "react-icons/go";
import { useNavigate } from "react-router";

function ActiveSessionItem({
  session,
  showMenu,
}: {
  session: ISession;
  showMenu: boolean;
}) {
  const navigate = useNavigate();

  const [avatarMenuAnchorEl, setAvatarMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const showAvatarMenu = Boolean(avatarMenuAnchorEl);

  const { user } = useUserContext();
  const { getPartner, archiveSession } = useSession();

  const partnerId = session.participantIds.find((id) => id !== user?.id);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setAvatarMenuAnchorEl(event.currentTarget);
  };

  const onCloseAvatarMenu = () => {
    setAvatarMenuAnchorEl(null);
  };

  const onArchiveSession = async () => {
    onCloseAvatarMenu();

    // set archive date for session
    await archiveSession(session.id);
    snack.success("Session archived");
  };

  if (!partnerId) return null;

  const partner = getPartner(partnerId);

  // get last message of conversation
  const lastMessage = session.messages[session.messages.length - 1];

  return (
    <div className="flex flex-row justify-between flex-1">
      <div
        className="flex flex-row items-center gap-2 p-2 cursor-pointer flex-1"
        onClick={() => navigate(`${ROUTES.SESSION}/${session.id}`)}
      >
        <ListItemAvatar
          sx={{
            pl: 1,
          }}
        >
          {partner?.avatar ? (
            <Avatar
              src={partner.avatar}
              imgProps={{
                referrerPolicy: "no-referrer",
              }}
            />
          ) : (
            <FaUser />
          )}
        </ListItemAvatar>
        <div className="flex flex-col flex-1">
          <div className="flex flex-row justify-between items-center">
            <Typography variant="body1">
              {partner?.name || "Unknown"}
            </Typography>
            <Typography variant="caption" color="GrayText">
              {moment(session.updatedAt).fromNow()}
            </Typography>
          </div>
          <Typography variant="body2" color="GrayText" className="line-clamp-2">
            <b>{lastMessage?.senderId === user?.id && "You: "}</b>
            {lastMessage?.content || "No messages"}
          </Typography>
        </div>
      </div>

      <div
        className={classNames(
          showMenu ? "visible" : "invisible",
          "flex items-center justify-center"
        )}
      >
        <IconButton
          onClick={handleMenuOpen}
          className="rotate-90"
          sx={{ mt: -1 }}
        >
          <GoKebabHorizontal />
        </IconButton>
      </div>

      <Menu
        open={showAvatarMenu}
        onClose={onCloseAvatarMenu}
        anchorEl={avatarMenuAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={onArchiveSession}>
          <span className="pr-2 text-red-700">Archive</span>
          <FaArchive className="fill-red-700" />
        </MenuItem>
      </Menu>
    </div>
  );
}

export default ActiveSessionItem;
