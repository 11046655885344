import { db } from "@/config/firebase";
import { ROUTES } from "@/constants/routes";
import { useSession } from "@/providers/session";
import { useUserContext } from "@/providers/user";
import { ISessionDTO } from "@/types";
import { snack } from "@/utils/snacks";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Portal,
  Typography,
} from "@mui/material";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useMemo } from "react";
import { useNavigate } from "react-router";

function SessionInviteDialog({ onClose }: { onClose: () => void }) {
  const navigate = useNavigate();

  const { user } = useUserContext();
  const { mostRecentInvitedSession, getPartner, joinSession } = useSession();

  const partner = useMemo(() => {
    if (!mostRecentInvitedSession || !user) return null;

    const partnerId = mostRecentInvitedSession.participantIds.find(
      (id) => id !== user.id
    );

    if (!partnerId) return null;

    return getPartner(partnerId);
  }, [getPartner, mostRecentInvitedSession, user]);

  const handleJoinSession = async () => {
    if (!mostRecentInvitedSession || !user) return;

    // join the session, the redirect the user
    await joinSession(mostRecentInvitedSession.id, user.id);

    // handle joining the session
    navigate(`${ROUTES.SESSION}/${mostRecentInvitedSession.id}`);
  };

  const handleDeclineSession = async () => {
    if (!mostRecentInvitedSession || !user) return;

    // handle declining the session
    try {
      const sessionDoc = await getDoc(
        doc(db, "sessions", mostRecentInvitedSession.id)
      );
      const session = sessionDoc.data() as ISessionDTO | undefined;

      if (!session) {
        snack.error("Failed to decline session invite.");
        return;
      }

      const updatedDeclinedBy = session.declinedBy || [];
      if (!updatedDeclinedBy.includes(user.id)) {
        updatedDeclinedBy.push(user.id);
      }

      // update the session with the decline
      const updatedSession: ISessionDTO = {
        ...session,
        declinedBy: updatedDeclinedBy,
      };

      await setDoc(
        doc(db, "sessions", mostRecentInvitedSession.id),
        updatedSession
      );
      onClose();
    } catch (error) {
      snack.error("Failed to decline session invite.");
    }
  };

  if (!mostRecentInvitedSession || !user || !partner) {
    return null;
  }

  return (
    <Portal>
      <Dialog open onClose={onClose}>
        <DialogTitle>You've been invited to a session!</DialogTitle>
        <DialogContent dividers>
          <Typography>
            You've been invited to join a session with {partner.name}. Click the
            button below to join.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleJoinSession} color="primary">
            Join Session
          </Button>
          <Button color="warning" onClick={handleDeclineSession}>
            Decline
          </Button>
        </DialogActions>
      </Dialog>
    </Portal>
  );
}

export default SessionInviteDialog;
