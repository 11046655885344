import { snack } from "@/utils/snacks";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { FirebaseError } from "firebase/app";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useState } from "react";
import { db } from "../../config/firebase";
import { IUser } from "../../types";

function FindPartnerDialog({
  open,
  onClose,
  onSelectPartner,
}: {
  open: boolean;
  onClose: () => void;
  onSelectPartner: (partner: IUser) => void;
}) {
  const [email, setEmail] = useState("");
  const [loadingPartner, setLoadingPartner] = useState(false);
  const [partnerUser, setPartnerUser] = useState<IUser | null>(null);

  const handleSearch = async () => {
    try {
      setLoadingPartner(true);

      // search for the partner in the db by exact email
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        snack.error("No matching documents.");
        return;
      }

      const firstPartner = querySnapshot.docs[0].data() as IUser;
      setPartnerUser(firstPartner);
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
      }
    } finally {
      setLoadingPartner(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle>Find a partner</DialogTitle>
      <DialogContent dividers>
        <Typography
          sx={{
            mb: 2,
          }}
        >
          Find your partner by email.
        </Typography>

        <TextField
          name="email"
          label="Enter Partner's Email"
          variant="outlined"
          placeholder="john.doe@gmail.com"
          fullWidth
          onChange={(event) => setEmail(event.target.value)}
          sx={{
            mb: 2,
          }}
        />

        {loadingPartner && <LinearProgress variant="indeterminate" />}

        {partnerUser !== null && (
          <div onClick={() => onSelectPartner(partnerUser)}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    src={partnerUser?.avatar}
                    alt={partnerUser?.name}
                    imgProps={{
                      referrerPolicy: "no-referrer",
                    }}
                  />
                }
                title={partnerUser?.name}
                subheader={partnerUser?.email}
              />
            </Card>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>

        <Button variant="contained" onClick={handleSearch}>
          Search
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FindPartnerDialog;
