import axios from "axios";

export async function createTestPrompt() {
  try {
    let url = "https://createtestprompt-s7hibdgevq-uc.a.run.app";
    // if (isDev() && isLocalhost()) {
    //   url =
    //     "http://127.0.0.1:5001/third-party-5b3c3/us-central1/createTestPrompt";
    // }

    const response = await axios.get(url);

    return response.data.choices[0].message.content;
  } catch (error) {
    console.error(error);
  }
}

interface ITestPromptMessage {
  person: string;
  message: string;
}

export async function createTestResponsePrompt(
  messages: ITestPromptMessage[],
  person: string
) {
  try {
    let url = "https://createtestresponseprompt-s7hibdgevq-uc.a.run.app";
    // if (isDev() && isLocalhost()) {
    //   url =
    //     "http://127.0.0.1:5001/third-party-5b3c3/us-central1/createTestResponsePrompt";
    // }

    const response = await axios.post(url, {
      messages,
      person,
    });

    return response.data.choices[0].message.content;
  } catch (error) {
    console.error(error);
  }
}

export async function createPromptSuggestion(prompt: string): Promise<string> {
  try {
    let url = "https://createpromptsuggestion-s7hibdgevq-uc.a.run.app";
    // if (isDev() && isLocalhost()) {
    //   url =
    //     "http://127.0.0.1:5001/third-party-5b3c3/us-central1/createPromptSuggestion";
    // }

    const response = await axios.post(url, { prompt });
    return response.data.choices[0].message.content || "";
  } catch (error) {
    console.error(error);
    return "Sorry, I couldn't generate any suggestions. Please try again.";
  }
}

export async function checkMessageForMediation(
  message: string,
  groundRules: string[]
): Promise<{
  isValid: boolean | null;
  message: string;
}> {
  try {
    let url = "https://checkmessageformediation-s7hibdgevq-uc.a.run.app";
    // if (isDev() && isLocalhost()) {
    //   url =
    //     "http://127.0.0.1:5001/third-party-5b3c3/us-central1/checkMessageForMediation";
    // }

    const response = await axios.post(url, { message, groundRules });

    const strResponse: string = response.data.choices[0].message.content || "";

    if (
      strResponse.toLowerCase() === "yes" ||
      strResponse.toLowerCase() === "true"
    ) {
      return {
        isValid: true,
        message: "",
      };
    }

    return {
      isValid: false,
      message: strResponse,
    };
  } catch (error) {
    console.error(error);
    return {
      isValid: null,
      message: "Sorry, I couldn't check the message for mediation.",
    };
  }
}
