import { db } from "@/config/firebase";
import { groundRules } from "@/constants/ground-rules";
import { useSession } from "@/providers/session";
import { useUserContext } from "@/providers/user";
import { IMessageDTO, ISession } from "@/types";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import { useState } from "react";
import { FaRobot } from "react-icons/fa";
import { MdCheck, MdMic, MdRefresh } from "react-icons/md";
import {
  checkMessageForMediation,
  createPromptSuggestion,
  createTestPrompt,
  createTestResponsePrompt,
} from "../../api";
import { isDev } from "../../utils/env";
import { snack } from "../../utils/snacks";

enum PromptStatus {
  Idle = "idle",
  Loading = "loading",
  Success = "success",
  Error = "error",
}

function MyTurn() {
  const { user } = useUserContext();
  const { activeSession } = useSession();

  const { id, name: userName = "" } = user || {};
  const {
    messages = [],
    optionalGroundRules = [],
    usersRequiringMediation = [],
  } = activeSession || {};

  const partner = activeSession?.participantUsers.find(
    (participant) => participant.id !== id
  );

  const [prompt, setPrompt] = useState<string>("");
  const [testPromptStatus, setTestPromptStatus] = useState<PromptStatus>(
    PromptStatus.Idle
  );
  const [promptSuggestion, setPromptSuggestion] = useState<string>("");
  const [promptSuggestionStatus, setPromptSuggestionStatus] =
    useState<PromptStatus>(PromptStatus.Idle);
  const [promptSuggestionHistory, setPromptSuggestionHistory] = useState<
    string[]
  >([]);
  const [allowMediate, setAllowMediate] = useState<boolean>(false);
  const [acceptStatus, setAcceptStatus] = useState<PromptStatus>(
    PromptStatus.Idle
  );
  const [submittedMessage, setSubmittedMessage] = useState<string>("");
  const [groundRulesFeedback, setGroundRulesFeedback] = useState<string>("");
  const [showPromptSuggestion, setShowPromptSuggestion] =
    useState<boolean>(false);

  const handleGetTestPrompt = async () => {
    if (!partner || !user) return;

    try {
      setTestPromptStatus(PromptStatus.Loading);

      let message = "";

      if (messages.length) {
        const testPromptMessages = messages.map((m) => ({
          message: m.content,
          person: m.senderId === id ? userName : partner.name,
        }));
        message = await createTestResponsePrompt(testPromptMessages, userName);
      } else {
        message = await createTestPrompt();
      }

      setTestPromptStatus(PromptStatus.Success);
      setPrompt(message);
      setAllowMediate(true);
    } catch (error) {
      setTestPromptStatus(PromptStatus.Error);
      console.error(error);

      snack.error("Failed to get test prompt");
    }
  };

  const handlePromptSuggestion = async () => {
    try {
      // reset prompt suggestion
      setPromptSuggestion("");

      setPromptSuggestionStatus(PromptStatus.Loading);
      const suggestion = await createPromptSuggestion(prompt);
      setPromptSuggestion(suggestion);
      setPromptSuggestionHistory([...promptSuggestionHistory, suggestion]);
      setPromptSuggestionStatus(PromptStatus.Success);
      setAllowMediate(false);
    } catch (error) {
      setPromptSuggestionStatus(PromptStatus.Error);
      console.error(error);
    }
  };

  const handlePromptChange = (value: string) => {
    setPrompt(value || "");

    if (value.length) {
      setAllowMediate(true);
    }
  };

  const handleAccept = async (messageValue: string, flagged = false) => {
    if (!activeSession || !user) return;

    const { id: sessionId } = activeSession;
    const { id: userId } = user;

    try {
      setAcceptStatus(PromptStatus.Loading);

      // add message to "messages" collection
      const message: IMessageDTO = {
        senderId: userId,
        content: messageValue,
        createdAt: serverTimestamp() as Timestamp,
        flagged,
      };

      if (flagged) {
        if (groundRulesFeedback) {
          message.flagged = true;
          message.flaggedReason = groundRulesFeedback;
        }

        if (promptSuggestion) {
          message.suggestedMessage = promptSuggestion;
        }
      }

      // get session data
      const sessionRef = doc(db, "sessions", sessionId);
      const sessionDoc = await getDoc(sessionRef);
      const session = sessionDoc.data() as ISession | undefined;
      if (!session) {
        snack.error("No matching session.");
        return;
      }

      // create a reference to the "messages" subcollection
      const messagesRef = collection(sessionRef, "messages");

      // add message to the "messages" subcollection
      await addDoc(messagesRef, message);

      setAcceptStatus(PromptStatus.Success);

      // clear prompt and suggestion
      setPromptSuggestion("");
      setPrompt("");
      setPromptSuggestionHistory([]);
    } catch (error) {
      snack.error("Failed to add message to session conversation.");
      setAcceptStatus(PromptStatus.Error);
    }
  };

  const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleMessageSubmit();

      // leave focus
      event.currentTarget.blur();
    }
  };

  const handleMessageSubmit = async () => {
    // puts the message into an intermediary state for the AI to check it
    // if the message passes the AI check, then it is added to the conversation
    // if the message fails the AI check, then the user is informed it breaks ground rules and is given a suggestion
    // the user can then choose to accept the suggestion or proceed anyways
    if (!prompt) return;

    setSubmittedMessage(prompt);
    setPrompt("");

    try {
      const resp = await checkMessageForMediation(prompt, [
        ...groundRules.required.map((rule) => rule.text || rule.title),
        ...optionalGroundRules,
      ]);

      // if the message is valid, add it to the conversation
      if (resp.isValid) {
        await handleAccept(prompt);
        setSubmittedMessage("");
        return;
      }

      // if the message is not valid, suggest a new message
      if (!resp.isValid) {
        setGroundRulesFeedback(resp.message);

        const promptSuggestion = await createPromptSuggestion(prompt);
        setPromptSuggestion(promptSuggestion);
        setShowPromptSuggestion(true);
      }
    } catch (error) {
      debugger;
      snack.error("Failed to check message for mediation.");
    }
  };

  const handleCloseDownSuggestion = () => {
    setPromptSuggestion("");
    setGroundRulesFeedback("");
    setSubmittedMessage("");
    setShowPromptSuggestion(false);
  };

  const handleAcceptSuggestion = async () => {
    if (!promptSuggestion) return;

    await handleAccept(promptSuggestion);
    handleCloseDownSuggestion();
  };

  const handleAcceptSubmittedMessage = async () => {
    if (!submittedMessage) return;

    await handleAccept(submittedMessage, true);
    handleCloseDownSuggestion();
  };

  if (!user || !activeSession) {
    return null;
  }

  return (
    <>
      <div className="flex w-full flex-1 flex-col justify-end px-2 box-border">
        {submittedMessage && (
          <div className="bg-primary-600 text-white rounded-md px-4 py-2 mb-4">
            <Typography sx={{ mb: 1 }}>{submittedMessage}</Typography>

            <LinearProgress variant="indeterminate" />
          </div>
        )}

        <div>
          <Typography
            variant="h6"
            align="center"
            color="GrayText"
            className="text-lg mb-1 mt-2"
          >
            What would you like to say?
          </Typography>

          <div className="mx-2 flex flex-col gap-2">
            <TextField
              multiline
              onChange={(event) => handlePromptChange(event.target.value)}
              value={prompt}
              onKeyDown={handleEnterPress}
            />

            <div className="flex flex-row gap-2">
              <LoadingButton
                loading={promptSuggestionStatus === PromptStatus.Loading}
                disabled={
                  !allowMediate ||
                  promptSuggestionStatus === PromptStatus.Loading
                }
                // onClick={handlePromptSuggestion}
                onClick={handleMessageSubmit}
                className="flex flex-1"
                variant="outlined"
              >
                Submit
              </LoadingButton>

              {isDev() && (
                <IconButton
                  disabled={testPromptStatus === PromptStatus.Loading}
                  onClick={handleGetTestPrompt}
                  color="primary"
                  aria-label="Get Test Prompt"
                >
                  <FaRobot />
                </IconButton>
              )}

              <IconButton
                disabled={
                  !prompt || promptSuggestionStatus === PromptStatus.Loading
                }
                onClick={() => {}}
                color="primary"
                aria-label="Start Recording"
              >
                <MdMic />
              </IconButton>
            </div>
          </div>
        </div>

        {promptSuggestion.length > 0 && (
          <div className="flex flex-col gap-4 my-4">
            <div className="flex flex-row gap-2">
              <LoadingButton
                loading={acceptStatus === PromptStatus.Loading}
                onClick={() => handleAccept(submittedMessage)}
                className="flex flex-1"
                variant="contained"
                color="primary"
              >
                Accept
              </LoadingButton>
              <LoadingButton
                loading={promptSuggestionStatus === PromptStatus.Loading}
                disabled={promptSuggestionStatus === PromptStatus.Loading}
                onClick={handlePromptSuggestion}
                className="flex flex-1"
                variant="contained"
                color="info"
              >
                Retry
              </LoadingButton>
            </div>
          </div>
        )}
      </div>

      {showPromptSuggestion && (
        <Dialog open={showPromptSuggestion} onClose={handleCloseDownSuggestion}>
          <DialogTitle>I beg your pardon?</DialogTitle>
          <DialogContent dividers>
            <div className="flex flex-col gap-2">
              <Typography sx={{ mb: 1 }}>
                <b>{submittedMessage}</b>
              </Typography>

              {groundRulesFeedback && (
                <Typography color="error">{groundRulesFeedback}</Typography>
              )}

              <Typography>Suggested text:</Typography>
              <Typography>
                <b>{promptSuggestion}</b>
              </Typography>
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={handlePromptSuggestion}
              startIcon={<MdRefresh />}
            ></Button>

            <Button onClick={handleAcceptSuggestion} startIcon={<MdCheck />}>
              Accept
            </Button>

            {usersRequiringMediation.includes(user.id) ? (
              <Button onClick={handleCloseDownSuggestion} color="warning">
                Cancel
              </Button>
            ) : (
              <Button color="warning" onClick={handleAcceptSubmittedMessage}>
                Send anyways
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default MyTurn;
