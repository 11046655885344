// @TODO add a more conventional set of ground rules for mediation

interface GroundRule {
  title: string;
  text?: string;
}

export const groundRules: {
  required: GroundRule[];
  optional: GroundRule[];
} = {
  required: [
    {
      title: "No yelling",
      text: "Avoid using all caps or excessive punctuation.",
    },
    {
      title: "No personal attacks",
      text: "Avoid attacking the other person personally.",
    },
    {
      title: "No hate speech",
      text: "Avoid using hate speech or slurs.",
    },
    {
      title: "No threats",
      text: "Avoid making threats against the other person.",
    },
    {
      title: "No spam",
      text: "Avoid sending the same message repeatedly.",
    },
  ],
  optional: [
    {
      title: "Avoid swearing",
      text: "Avoid using swear words or offensive language.",
    },
  ],
};

/**
 * Sources:
 * - https://tilt.colostate.edu/netiquette-ground-rules-for-online-discussions/
 */
