import { checkMessageForMediation, createPromptSuggestion } from "@/api";
import Header from "@/components/Header";
import LoadingUserDialog from "@/components/LoadingUserDialog";
import { db } from "@/config/firebase";
import { useOnboarding } from "@/providers/onboarding";
import { useUserContext } from "@/providers/user";
import { snack } from "@/utils/snacks";
import { classNames } from "@/utils/styles";
import { LoadingButton } from "@mui/lab";
import { Button, TextField, TextareaAutosize, Typography } from "@mui/material";
import {
  Timestamp,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { MdFeedback } from "react-icons/md";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import FeedbackDialog from "./FeedbackDialog";

interface GroundRule {
  title: string;
  value: string;
}

enum LandingGroundRules {
  YELLING = "YELLING",
  PERSONAL_ATTACKS = "PERSONAL_ATTACKS",
  THREATS = "THREATS",
  SPAM = "SPAM",
  CLEAN_LANGUAGE = "CLEAN_LANGUAGE",
  EXCESSIVE_PUNCTUATION = "EXCESSIVE_PUNCTUATION",
  OFFENSIVE = "OFFENSIVE",
  SLURS = "SLURS",
  // INAPPROPRIATE_CONTENT = "INAPPROPRIATE_CONTENT",
}

const landingGroundRules: {
  [key in LandingGroundRules]: GroundRule;
} = {
  YELLING: {
    title: "Yelling",
    value: "Avoid using all caps or excessive punctuation.",
  },
  PERSONAL_ATTACKS: {
    title: "Personal Attacks",
    value: "Avoid attacking the other person personally.",
  },
  THREATS: {
    title: "Threats",
    value: "Avoid making threats against another person or group of people.",
  },
  SPAM: {
    title: "Repetitive",
    value: "Avoid sending the same message repeatedly.",
  },
  CLEAN_LANGUAGE: {
    title: "Profanity",
    value: "Avoid swearing, cursing, or cussing.",
  },
  EXCESSIVE_PUNCTUATION: {
    title: "Excessive Punctuation",
    value: "Avoid using excessive punctuation.",
  },
  OFFENSIVE: { title: "Offensive", value: "No offensive language." },
  SLURS: { title: "Slurs", value: "No slurs." },
  // INAPPROPRIATE_CONTENT: {
  //   title: "Inappropriate Content",
  //   value: "No inappropriate language.",
  // },
};

// const landingGroundRules: GroundRule[] = [
//   { title: "Yelling", value: "No yelling" },
//   { title: "Personal Attacks", value: "No personal attacks" },
//   { title: "Hate Speech", value: "No hate speech" },
//   { title: "Threats", value: "No threats" },
//   { title: "Spam", value: "No spam" },
//   { title: "Swearing", value: "Avoid swearing" },
//   { title: "Excessive Punctuation", value: "No excessing punctuation" },
//   { title: "Offensive", value: "No offensive language" },
//   { title: "Slurs", value: "No slurs" },
//   { title: "Inappropriate Content", value: "No inappropriate content" },
// ];

const defaultActiveGroundRules: GroundRule[] = [
  landingGroundRules[LandingGroundRules.YELLING],
  landingGroundRules[LandingGroundRules.PERSONAL_ATTACKS],
  landingGroundRules[LandingGroundRules.THREATS],
  landingGroundRules[LandingGroundRules.SPAM],
  landingGroundRules[LandingGroundRules.CLEAN_LANGUAGE],
  landingGroundRules[LandingGroundRules.EXCESSIVE_PUNCTUATION],
  landingGroundRules[LandingGroundRules.OFFENSIVE],
  landingGroundRules[LandingGroundRules.SLURS],
  // landingGroundRules[LandingGroundRules.INAPPROPRIATE_CONTENT],
];

enum SubmittedStatus {
  NOT_SUBMITTED = "NOT_SUBMITTED",
  SUBMITTING = "SUBMITTING",
  SUBMITTED = "SUBMITTED",
}

function LandingPage() {
  const navigate = useNavigate();

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { isAuthenticated } = useUserContext();
  const { onboardingComplete } = useOnboarding();

  const [message, setMessage] = useState<string>("");
  const [checkedMessage, setCheckedMessage] = useState<string>("");
  const [showLoadingUserDialog, setShowLoadingUserDialog] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<SubmittedStatus>(
    SubmittedStatus.NOT_SUBMITTED
  );
  const [isValidMessage, setIsValidMessage] = useState<boolean | null>(null);
  const [messageSuggestion, setMessageSuggestion] = useState<string | null>(
    null
  );
  const [messageWarning, setMessageWarning] = useState<string | null>(null);

  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [violatedGroundRules, setViolatedGroundRules] = useState<GroundRule[]>(
    []
  );

  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleAcceptSuggestion = () => {
    setMessage(messageSuggestion || "");
    setCheckedMessage(messageSuggestion || "");
    setMessageSuggestion(null);
    setIsValidMessage(true);
    setSubmitStatus(SubmittedStatus.NOT_SUBMITTED);
    setViolatedGroundRules([]);
  };

  const handleOpenFeedbackDialog = () => {
    setShowFeedbackDialog(true);
  };

  // const handleToggleRule = (rule: GroundRule) => {
  //   if (activeRules.some((r) => r.title === rule.title)) {
  //     setActiveRules(activeRules.filter((r) => r.title !== rule.title));
  //   } else {
  //     setActiveRules([...activeRules, rule]);
  //   }
  // };

  const handleTryIt = async () => {
    if (!message) return;

    // reset violated ground rules
    setViolatedGroundRules([]);

    try {
      const landingMessagesRef = collection(db, "landingMessages");
      await addDoc(landingMessagesRef, {
        message,
        createdAt: serverTimestamp() as Timestamp,
      });

      setMessageWarning(null);
      setSubmitStatus(SubmittedStatus.SUBMITTING);
      const { isValid, message: messageWarning } =
        await checkMessageForMediation(message, [
          ...defaultActiveGroundRules.map((rule) => rule.value),
        ]);

      // check messageWarning for the violated ground rules
      const violatedGroundRules = defaultActiveGroundRules.filter((rule) =>
        messageWarning?.includes(rule.value)
      );
      setViolatedGroundRules(violatedGroundRules);

      setCheckedMessage(message);

      if (isValid === false) {
        let suggestion = await createPromptSuggestion(message);

        // remove double quotes around suggestion
        suggestion = suggestion.replace(/^"(.*)"$/, "$1");

        setMessageSuggestion(suggestion);
      } else {
        setMessageSuggestion(null);
      }

      if (isValid !== null) {
        setIsValidMessage(isValid);
        setMessageWarning(messageWarning);
      } else {
        snack.error("Failed to check message");
      }
    } catch (error) {
      snack.error("Failed to check message");
    } finally {
      setSubmitStatus(SubmittedStatus.SUBMITTED);
    }
  };

  const handleSubmitEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // add email subscription
    try {
      await addDoc(collection(db, "landingSubscriptions"), {
        email: e.currentTarget.email.value,
        createdAt: serverTimestamp() as Timestamp,
      });

      setIsSubscribed(true);

      sessionStorage.setItem("isSubscribed", "true");
    } catch (error) {
      snack.error("Failed to subscribe");
    }
  };

  useEffect(() => {
    // redirect to dashboard if onboarding is completed, and no active session
    if (isAuthenticated) {
      navigate(ROUTES.DASHBOARD);
      return;
    }
  }, [isAuthenticated, navigate, onboardingComplete]);

  // show loading dialog if user is authenticated, but onboarding status is unknown
  useEffect(() => {
    if (isAuthenticated && onboardingComplete === null) {
      setShowLoadingUserDialog(true);
    }
  }, [isAuthenticated, onboardingComplete]);

  // check session storage for subscription status
  useEffect(() => {
    const isSubscribed = sessionStorage.getItem("isSubscribed");
    if (isSubscribed === "true") {
      setIsSubscribed(true);
    }
  }, []);

  return (
    <>
      <Header />

      <main className="flex flex-col gap-8 lg:gap-20 pb-10">
        <div />
        <div className="flex flex-col items-center justify-center gap-2">
          <Typography variant="h5" textAlign="center" sx={{ fontWeight: 700 }}>
            When you're ready to stop being (less of) a{" "}
            <b>
              <i>Donkey</i>
            </b>
          </Typography>
          <Typography variant="h5" textAlign="center">
            😈
          </Typography>
          <Typography textAlign="center">
            Translating what you <b>WANT</b> to say
          </Typography>
          <Typography textAlign="center">
            to what you <b>SHOULD</b> say.
          </Typography>
          <Typography variant="h5" textAlign="center">
            😇
          </Typography>
        </div>

        <div className="flex flex-col w-full mx-auto min-h-20 max-w-4xl px-2 box-border">
          {/* <div className="flex flex-1 flex-row justify-end mb-2">
            <Button
              onClick={handleOpenFeedbackDialog}
              variant="outlined"
              size="small"
              endIcon={<MdFeedback />}
            >
              Feedback
            </Button>
          </div> */}
          <div
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }}
            className={classNames(
              "flex flex-col justify-between rounded-md border-2 border-solid p-4",
              submitStatus === SubmittedStatus.NOT_SUBMITTED
                ? "border-gray-400"
                : "",
              submitStatus === SubmittedStatus.SUBMITTED && isValidMessage
                ? "border-purple-600"
                : "",
              submitStatus === SubmittedStatus.SUBMITTED && !isValidMessage
                ? "border-red-600"
                : ""
            )}
          >
            <div className="flex flex-row flex-wrap gap-1 mb-4">
              {Object.values(landingGroundRules).map((rule) => (
                <span
                  key={rule.title}
                  // onClick={() => handleToggleRule(rule)}
                  className={classNames(
                    "text-xs lowercase font-medium bg-gray-200 text-gray-800 px-2 py-1 rounded-full cursor-pointer",
                    violatedGroundRules.some(
                      (r) => r.title.toLowerCase() === rule.title.toLowerCase()
                    )
                      ? "bg-purple-600 !text-white"
                      : ""
                  )}
                >
                  {rule.title}
                </span>
              ))}
            </div>

            <TextareaAutosize
              ref={inputRef}
              maxLength={280}
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              placeholder="Enter a message to check"
              className={classNames(
                "w-full text-xl border-none font-medium placeholder:text-gray-400 focus:outline-none mb-2",
                submitStatus === SubmittedStatus.NOT_SUBMITTED
                  ? "text-black"
                  : "",
                submitStatus === SubmittedStatus.SUBMITTED && isValidMessage
                  ? "text-purple-600"
                  : "",
                submitStatus === SubmittedStatus.SUBMITTED && !isValidMessage
                  ? "text-red-600"
                  : ""
              )}
            />

            {submitStatus === SubmittedStatus.SUBMITTED &&
              !isValidMessage &&
              messageSuggestion && (
                <div className="w-full text-xl border-none font-medium text-purple-800 mb-2">
                  <b>Suggested:</b> {messageSuggestion}
                </div>
              )}

            <div className="flex flex-row justify-between">
              <div>
                {messageSuggestion && (
                  <Button variant="outlined" onClick={handleAcceptSuggestion}>
                    Accept Suggestion
                  </Button>
                )}

                {isValidMessage && (
                  <div className="w-full text-sm border-none text-purple-600 mt-1">
                    Looks good! 🎉
                  </div>
                )}
              </div>

              <LoadingButton
                loading={submitStatus === SubmittedStatus.SUBMITTING}
                disabled={
                  submitStatus === SubmittedStatus.SUBMITTING ||
                  !message ||
                  message === checkedMessage
                }
                variant="outlined"
                onClick={handleTryIt}
              >
                Check It 🤞
              </LoadingButton>
            </div>
          </div>

          <div className="flex flex-row justify-between items-center">
            <Typography
              variant="caption"
              color={message.length === 280 ? "red" : "gray"}
            >
              {message.length} / 280
            </Typography>

            {!isValidMessage && messageWarning && (
              <div className="text-sm border-none text-red-600 mt-1">
                <b className="mr-1">Warning:</b> {messageWarning}
              </div>
            )}
          </div>
        </div>

        {isSubscribed ? (
          <div className="flex max-w-sm mx-auto flex-col px-4">
            <Typography variant="h5" textAlign="center">
              Thank you!
            </Typography>
            <Typography textAlign="center">
              You're now on the path to being less of a
            </Typography>
            <Typography variant="h3" textAlign="center">
              🫏 😏
            </Typography>
          </div>
        ) : (
          <form
            className="flex flex-col justify-center items-center gap-2 max-w-sm mx-auto px-4"
            onSubmit={handleSubmitEmail}
          >
            <Typography variant="h4" align="center">
              Love this product?
            </Typography>
            <Typography align="center">
              <b>Subscribe</b> below to receive <b>exclusive access</b> to the
              mobile version.
            </Typography>
            <TextField
              fullWidth
              placeholder="Email"
              variant="outlined"
              type="email"
              name="email"
              id="email"
            />

            <div>
              <Button
                type="submit"
                variant="contained"
                size="large"
                endIcon={<span>🙏</span>}
                fullWidth
              >
                I Need This
              </Button>
            </div>
          </form>
        )}

        <div className="flex flex-col max-w-md mx-auto gap-2">
          <Typography variant="h5" align="center">
            Are we missing something?
          </Typography>
          <Typography align="center">Give us your feedback here</Typography>
          <Button
            variant="outlined"
            onClick={handleOpenFeedbackDialog}
            endIcon={<MdFeedback />}
          >
            Feedback
          </Button>
        </div>
      </main>

      {showLoadingUserDialog && (
        <LoadingUserDialog onClose={() => setShowLoadingUserDialog(false)} />
      )}

      {showFeedbackDialog && (
        <FeedbackDialog onClose={() => setShowFeedbackDialog(false)} />
      )}
    </>
  );
}

export default LandingPage;
