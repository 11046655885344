import { useSession } from "@/providers/session";
import { useUserContext } from "@/providers/user";
import { copyLink } from "@/utils/copy";
import { Button, Typography } from "@mui/material";
import { purple } from "@mui/material/colors";
import { useSnackbar } from "notistack";
import { QRCodeSVG } from "qrcode.react";
import { FaRegClipboard } from "react-icons/fa";

function WaitingOnPartner() {
  const { activeSession } = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useUserContext();

  const { owner, acceptedBy = [], messages = [] } = activeSession || {};

  const shareLink = window.location.href;

  const handleCopySessionLink = () => {
    copyLink(shareLink);

    enqueueSnackbar("Session link copied to clipboard", {
      variant: "success",
      autoHideDuration: 1500,
      style: { backgroundColor: purple[800], color: "white" },
    });
  };

  if (!owner || !user || user.id !== owner) return null;

  // do not show if partner has accepted
  if (acceptedBy.length > 0) return null;

  // do not show if there are messages
  if (messages.length > 0) return null;

  return (
    <div className="flex flex-1 flex-col justify-center gap-2">
      <Typography variant="h6" align="center" color="GrayText">
        Share this session with a partner
      </Typography>
      <Button
        variant="outlined"
        endIcon={<FaRegClipboard />}
        fullWidth
        onClick={handleCopySessionLink}
      >
        Copy Session Link
      </Button>
      <div className="flex items-center justify-center mt-8">
        <QRCodeSVG value={shareLink} />
      </div>
    </div>
  );
}

export default WaitingOnPartner;
