import { useSession } from "@/providers/session";
import { useUserContext } from "@/providers/user";
import Message from "./Message";

function Conversation() {
  const { activeSession } = useSession();
  const { user } = useUserContext();

  const sessionUsers = activeSession?.participantUsers || [];

  const partner = sessionUsers.find(
    (participant) => participant.id !== user?.id
  );

  if (!activeSession || !activeSession.messages || !user || !partner)
    return null;

  return (
    <div
      style={{
        maxHeight: window.innerHeight * 0.4,
      }}
      className="overflow-y-auto"
      id="message-list"
    >
      {activeSession?.messages.map((message) => (
        <Message key={message.id} message={message} />
      ))}
    </div>
  );
}

export default Conversation;
