import { blue, red, teal, yellow } from "@mui/material/colors";
import { enqueueSnackbar } from "notistack";

export enum SnackVariant {
  Error = "error",
  Info = "info",
  Success = "success",
  Warning = "warning",
}

type SnackVariantType = "error" | "info" | "success" | "warning";

export const snack = {
  error: (message: string, duration?: number) =>
    createSnack(message, {
      variant: SnackVariant.Error,
      duration,
    }),
  info: (message: string, duration?: number) =>
    createSnack(message, {
      variant: SnackVariant.Info,
      duration,
    }),
  success: (message: string, duration?: number) =>
    createSnack(message, {
      variant: SnackVariant.Success,
      duration,
    }),
  warning: (message: string, duration?: number) =>
    createSnack(message, {
      variant: SnackVariant.Warning,
      duration,
    }),
};

function createSnack(
  message: string,
  {
    variant = SnackVariant.Info,
    duration = 1500,
  }: {
    variant?: SnackVariant | SnackVariantType;
    duration?: number;
  }
) {
  let backgroundColor = "";
  switch (variant) {
    case SnackVariant.Error:
      backgroundColor = red[700];
      break;

    case SnackVariant.Info:
      backgroundColor = blue[700];
      break;

    case SnackVariant.Success:
      backgroundColor = teal[700];
      break;

    case SnackVariant.Warning:
      backgroundColor = yellow[700];
      break;
  }

  enqueueSnackbar(message, {
    variant: variant,
    autoHideDuration: duration,
    style: { backgroundColor: backgroundColor, color: "white" },
  });
}
